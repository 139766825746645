import React from 'react';

type LabelledDropdownProps = {
  label: string;
  value: string;
  options: Array<{ name: string, value: string }>
  setValue: (value: string) => void;
}

function LabelledDropdown({ label, value, setValue, options }: LabelledDropdownProps) {
  return (
    <label>
      {label}
      <select
        className='form-select block w-full rounded-md border-0 py-2 px-2 text-stone-900 shadow-sm ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6'
        value={value}
        onChange={(e) => setValue(e.target.value)}
      >
        {
          !value && <option className='text-black' value={undefined}>-- Select Value</option>
        }
        {options.map((option, index) => {
          return <option className='text-black' key={index} value={option.value}>
            {option.name}
          </option>
        })}
      </select>
    </label>
  );
}

export default LabelledDropdown;
