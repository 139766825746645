import {IncidentSummary} from "./IncidentSummary";
import {TwoColumns} from "../../components/Layouts/TwoColumns";
import {StoneBox} from "../../components/Layouts/StoneBox";
import LabelledDropdown from "../../components/FormElements/LabelledDropdown";
import {useEffect, useState} from "react";
import {getHomeAreas} from "../../services/httpService";
import {IHomeArea} from "../HomeArea/Interfaces";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {IncidentChart} from "./IncidentChart/IncidentChart";
import {IncidentTable} from "./IncidentTable/IncidentTable";
import {NavigateBackButton} from "../../components/Buttons/NavigateBackButton";

export const IncidentSummaryPage = () =>  {
  let {homeAreaId} = useParams() as { homeAreaId: string };
  const [options, setOptions] = useState <IHomeArea[]> ([])
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const viewString = searchParams.get('view');
  console.log({viewString})

  // @ts-ignore
  const [mainContent, setMainContent] = useState<'chart' | 'table'>(viewString ?? 'chart');



  const showIncidentTable = () => {
    setMainContent('table')
    setSearchParams({view: 'table'})
  }

  const showIncidentChart = () => {
    setMainContent('chart')
    setSearchParams({view: 'chart'})
  }

  useEffect (() =>  {
    const fetchHomeAreas = async () =>
    {
      const homeAreas = await getHomeAreas();
      setOptions(homeAreas);
    }

    fetchHomeAreas().catch()
  }, [])

  const handleHomeAreaChange = (homeAreaId: string) => {
    navigate(`/home-areas/${homeAreaId}/incident-summary`)
  }

  const side = <div className="flex flex-col gap-4">
    <StoneBox>
      <NavigateBackButton onClick={() => {
        navigate(`/home-areas/${homeAreaId}`)
      }} label={'Back to Home Area'}/>
      <hr className='mb-4'/>
      <div className='w-36'>
        <LabelledDropdown label="Home Area" value={homeAreaId} options={
          options.map(homeArea => {
            return {
              name: homeArea.name,
              value: homeArea.id,
            }
          })
        } setValue={handleHomeAreaChange}/>
      </div>
    </StoneBox>

    <IncidentSummary/>
  </div>

  const main = mainContent === 'chart'
    ? <IncidentChart showIncidentTable={showIncidentTable}/>
    : <IncidentTable showIncidentChart={showIncidentChart}/>

  return (
    <TwoColumns side={side} main={main}/>
  )
}