import React, {createContext, ReactNode, useContext} from 'react';
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export interface NotificationContextType {
  showSuccess: (message: string) => void,
  showError: (message: string) => void,
}

interface NotificationProviderProps {
  children: ReactNode;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const NotificationProvider: React.FC<NotificationProviderProps> = ({children}) => {
  const showSuccess = (message: string): void => {
    toast.success(message);
  }

  const showError = (message: string): void => {
    toast.error(message);
  }

  const value: NotificationContextType = {showSuccess, showError};

  return (
    <NotificationContext.Provider value={value}>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = (): NotificationContextType => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotification must be used within an NotificationProvider');
  }
  return context;
};
