import React from 'react';

type LabelledTimeInputProps = {
  label: string;
  value: string;
  setValue: (value: string) => void;
}
//
// <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email</label>
// <div className="mt-2">
//     <input type="email" name="email" id="email"
//            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//            placeholder="you@example.com">
// </div>

function LabelledTimeInput({label, value, setValue}: LabelledTimeInputProps) {
  return (
    <label className="block w-full text-md leading-6 text-white">
      {label}
      <input
        type="time"
        className="form-input block w-full rounded-md border-0 py-1.5 px-2 text-stone-900 shadow-sm ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </label>
  );
}

export default LabelledTimeInput;
