import {useEffect, useRef, useState} from 'react';
import {UserDropdownMenu} from '../UserDropdownMenu/UserDropdownMenu';
import {useNavigate} from 'react-router-dom';
import {useAuth} from "../../context/AuthContext";
import {getHomeAreas} from '../../services/httpService';
import ElephasCareLogo from "../Images/ElephasCareLogo";


export const TopBar = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false)
  const { user, logout } = useAuth()
  const getUserInitial = (name: string) => {
    let initial = '';
    const words = name.split(' ')
    words.map((word) => initial += word.charAt(0))
    return initial
  }
  const currentDate = new Date().toLocaleDateString('en-us', {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric"
  })

  function handleLogout() {
    logout();
    navigate('/login')
  }

  function handleSettingsClick() {
    navigate('/settings')
    setMenuOpen(false)
  }

  function handleEditProfileClick() {
    navigate('/profile')
    setMenuOpen(false)
  }

  const handleLogoClick = async () => {
    const homeAreas = await getHomeAreas();
    const firstHomeArea = homeAreas[0].id
    navigate(`/home-areas/${firstHomeArea}`);
  }

  const dropDownRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (dropDownRef.current && !(dropDownRef.current as HTMLDivElement).contains(event.target)) {
        setMenuOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    if (!menuOpen) {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropDownRef, menuOpen]);

  return (
    <div className="my-6 flex flex-col sm:flex-row justify-between items-center text-white">
      <div className='flex cursor-pointer' onClick={handleLogoClick}>
        <ElephasCareLogo/>
      </div>
      <div className='flex items-center'>
        <div className="text-lg mr-4">{currentDate}</div>
        <div
          className="hover:cursor-pointer bg-white text-sky-500 text-lg font-bold border-sky-500 border-4 p-2 rounded-full relative"
          ref={dropDownRef}>
          <div className="select-none w-6 h-6 text-center align-top flex items-center justify-center"
               onClick={() => setMenuOpen(!menuOpen)}>{user ? getUserInitial(user.name) : 'GS'}</div>
          {menuOpen &&
            <div>
              <UserDropdownMenu username={user?.name || ''} email={user?.email || ''} handleLogout={handleLogout}
                                handleSettingsClick={handleSettingsClick}
                                handleEditProfileClick={handleEditProfileClick}/>
            </div>
          }
        </div>
      </div>
    </div>
  );
}
