import axios from 'axios';
import {IRoomSettings} from '../pages/RoomSettings';
import {IExceptionThresholdGroup, IFacilitySettings} from "../pages/FacilitySettings/Interfaces";
import {IDevice} from '../pages/DeviceManagement';
import {IResidentProfile} from "../pages/RoomSettings/RoomSettingsView/Interfaces";
import {IActivationCode} from '../pages/ActivateDevice/IActivateDevice';
import {IStartAndEnd} from "../pages/ManageAbsence";
import {IBaseRoom, IChart, IDailySummary, IDetailedRoom, IHomeArea} from "../pages/HomeArea/Interfaces";
import {ManagedUser} from "../pages/UserManagement";
import {Role} from "../context/IAuthContext";
import {IChartGraphData} from "../components/Chart/Chart";

const API_URL = process.env.REACT_APP_API_URL ?? 'http://localhost:8000/api';

function getAuthToken() {
  return 'Bearer ' + localStorage.getItem('bearer_token');
}

export async function updateUserProfile(payload: {
  currentPassword: string,
  newPassword: string,
  confirmNewPassword: string
}) {
  await axios.patch(`${API_URL}/profile`, payload, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
}

export async function getHomeAreaRooms(homeAreaId: string) {

  const roomsResponse = await axios.get(`${API_URL}/home-areas/${homeAreaId}/beds`, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  const roomsData: IBaseRoom[] = await roomsResponse.data.data.beds;
  return roomsData;
}

export async function getHomeAreas() {
  const homeAreasResponse = await axios.get(`${API_URL}/home-areas`, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  const homeAreasData: IHomeArea[] = homeAreasResponse.data.data.homeAreas;
  return homeAreasData;
}

export async function getRoom(bedId: string) {
  const roomResponse = await axios.get(`${API_URL}/beds/${bedId}`, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  const roomData: IDetailedRoom = roomResponse.data.data.bed;
  return roomData;
}

export async function getDailySummary(residentId: string, dailySummaryDate: string) {
  const roomResponse = await axios.get(`${API_URL}/residents/${residentId}/daily-summary?date=${dailySummaryDate}`, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  const dailySummary: IDailySummary = roomResponse.data.data.daily_summary;
  return dailySummary;
}

export interface IIncidentSummary {
  falls: number
  outOfBedOccurrences: number
  outOfRoomOccurrences: number
  noMovementDetectedOccurrences: number
  bedExitAttempts: number
}

export async function getIncidentSummary(homeAreaId: string, incidentSummaryDate: string) {
  const roomResponse = await axios.get(`${API_URL}/home-areas/${homeAreaId}/incident-summary?date=${incidentSummaryDate}`, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  const incidentSummary: IIncidentSummary = roomResponse.data.data.incident_summary;
  return incidentSummary;
}

export async function getIncidentChart(homeAreaId: string, reportingPeriod: string): Promise<IChartGraphData> {
  const roomResponse = await axios.get(`${API_URL}/home-areas/${homeAreaId}/incident-chart?reportingPeriod=${reportingPeriod}`, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  return roomResponse.data.data.graph;
}

export async function getAllIncidents(homeAreaId: string, days?: number) {

  let url = `${API_URL}/home-areas/${homeAreaId}/all-incidents` + (days ? `?days=${days}` : ``);

  const roomResponse = await axios.get(url, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  return roomResponse.data.data.incidents;
}

export async function vacateRoom(roomId: string) {
  const vacateResponse = await axios.post(`${API_URL}/dashboard/rooms/${roomId}/vacate`, {}, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  return vacateResponse.data;
}

export async function storeManualBedStatus(bedId: string, status: string) {
  const response = await axios.post(`${API_URL}/beds/${bedId}/status`, {
    status
  }, {
    headers: {
      'Authorization': getAuthToken()
    }
  });

  return response.data;
}

export async function triggerTestNotification(deviceId: string) {
  const response = await axios.post(`${API_URL}/devices/${deviceId}/push-notification-test`, {}, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  return response.data;
}

export async function submitAbsence(roomId: string, payload: { startingAt: string, endingAt: string }) {
  const vacateResponse = await axios.post(`${API_URL}/dashboard/rooms/${roomId}/absence`, payload, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  return vacateResponse.data;
}

export async function getAbsence(roomId: string): Promise<IStartAndEnd> {
  const absenceData = await axios.get(`${API_URL}/dashboard/rooms/${roomId}/absence`, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  return absenceData.data;
}

export async function revertAbsence(roomId: string) {
  const absenceData = await axios.delete(`${API_URL}/dashboard/rooms/${roomId}/absence`, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  return absenceData.data;
}

export async function finalizeAbsence(roomId: string, payload: { startingAt: string, endingAt: string }) {
  const authToken = getAuthToken();
  console.log({authToken})
  const absenceData = await axios.post(`${API_URL}/dashboard/rooms/${roomId}/absence/finalize`, payload, {
    headers: {
      'Authorization': authToken
    }
  });
  return absenceData.data;
}
export async function transferRoom(roomId: string, destinationRoomId: string) {
  const transferResponse = await axios.post(`${API_URL}/dashboard/rooms/${roomId}/transfer/${destinationRoomId}`, {}, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  return transferResponse.data;
}

export async function getResidentSettings(residentId: string) {
  const roomResponse = await axios.get(`${API_URL}/residents/${residentId}/settings`, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  const roomSettings: IRoomSettings = roomResponse.data.data.resident_settings;
  return roomSettings;
}

export async function getResidentProfile(roomId: string) {
  const roomResponse = await axios.get(`${API_URL}/residents/${roomId}`, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  const roomData: IResidentProfile = roomResponse.data.data.resident;
  return roomData;
}


export async function updateResidentSettings(roomId: string, roomSettings: IRoomSettings) {
  console.log(roomSettings);
  const roomResponse = await axios.patch(`${API_URL}/residents/${roomId}/settings`, roomSettings, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  return roomResponse.data;
}

export async function updateResidentProfile(residentId: string, residentProfile: IResidentProfile) {
  const roomResponse = await axios.patch(`${API_URL}/residents/${residentId}`, residentProfile, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  return roomResponse.data;
}

export async function getRoomCharts(residentId: string, reportingPeriod: string) {
  const roomResponse = await axios.get(`${API_URL}/residents/${residentId}/metrics?reportingPeriod=${reportingPeriod}`, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  const roomCharts: IChart[] = roomResponse.data.data.metrics;
  return roomCharts;
}

export async function getFacilitySettings() {
  const response = await axios.get(`${API_URL}/facility/settings`, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  const data: IFacilitySettings = response.data.data.facility_settings;
  return data;
}

export async function getExceptionThresholdOptions() {
  const response = await axios.get(`${API_URL}/facility/settings/options`, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  const data: IExceptionThresholdGroup = response.data;
  return data;
}

export async function getDevices() {
  const response = await axios.get(`${API_URL}/devices`, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  const data: IDevice[] = response.data.data.devices;
  return data;
}

export async function getUsers() {
  const response = await axios.get(`${API_URL}/users`, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  const data: ManagedUser[] = response.data.data.users;
  return data;
}

export async function getUser(userId: string) {
  const response = await axios.get(`${API_URL}/users/${userId}`, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  const data: ManagedUser = response.data.data.user;
  return data;
}

export async function updateUser(userId: string, payload: {
  name: string,
  email: string,
  role: string,
  password?: string
}) {
  const response = await axios.patch(`${API_URL}/users/${userId}`, payload, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  return response.data;
}

export async function deleteUser(userId: string) {
  const response = await axios.delete(`${API_URL}/users/${userId}`, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  return response.data;
}

interface INewDevice {
  name: string,
  accessLevel: number;
  homeAreaId: string,
  role: string,
}

export async function getDevice(deviceId: string) {
  const deviceResponse = await axios.get(`${API_URL}/devices/${deviceId}`, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  return deviceResponse.data.data.device as IDevice;
}

export async function updateDevice(deviceId: string, payload: {
  name: string,
  homeAreaId: string,
  role: string,
  accessLevel: number
}) {
  const response = await axios.patch(`${API_URL}/devices/${deviceId}`, payload, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  return response.data;
}

export async function deleteDevice(deviceId: string) {
  const response = await axios.delete(`${API_URL}/devices/${deviceId}`, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  return response.data;
}

export async function postNewDevice(device: INewDevice) {
  const roomResponse = await axios.post(`${API_URL}/devices`, device, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  return roomResponse.data;
}

interface INewUser {
  name: string,
  email: string,
  role: Role,
  password: string,
}

export async function postNewUser(user: INewUser) {
  const roomResponse = await axios.post(`${API_URL}/users`, user, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  return roomResponse.data;
}



export async function getActivationCode(deviceId: string) {
  const deviceResponse = await axios.put(`${API_URL}/devices/${deviceId}/activation-code`, {}, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  return deviceResponse.data.data.activation_code as IActivationCode;
}

export async function updateFacilitySettings(facilitySettings: IFacilitySettings) {
  const roomResponse = await axios.post(`${API_URL}/facility/settings`, facilitySettings, {
    headers: {
      'Authorization': getAuthToken()
    }
  });
  return roomResponse.data;
}

export async function loginDetails(username: string, password: string) {
  const loginResponse = await axios.post(`${API_URL}/user/login/`, {
    email: username, password, device_name: 'browser'
  });

  return await loginResponse.data;
}
