import React from 'react';

type LabelledCheckboxProps = {
    label: string;
    isChecked: boolean;
    setIsChecked: (isChecked: boolean) => void;
}

function LabelledCheckbox({ label, isChecked, setIsChecked}: LabelledCheckboxProps) {
    return (
      <label className="text-md">
            <input
                type="checkbox"
                className="mr-2 h-4 w-4"
                checked={isChecked}
                onChange={e => setIsChecked(e.target.checked)}
            />
            { label }
        </label>
    );
}

export default LabelledCheckbox;
