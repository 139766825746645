import React from 'react';

type LabelledTextInputProps = {
  label: string;
  value: string;
}


function LabelledText({label, value}: LabelledTextInputProps) {
  return (
    <label className="block w-full text-md font-medium leading-6 text-white">
      {label}
      <input
        type="text"
        className="mt-1 disabled:bg-stone-700 disabled:cursor-not-allowed block w-full rounded-md border-0 py-2 px-2 text-white shadow-sm ring-1 ring-inset ring-stone-400 placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6"
        value={value}
        disabled
      />
    </label>
  );
}

export default LabelledText;
