import * as React from 'react'
import {ManagedUser} from './index';

function UserRow({user, editUser}: { user: ManagedUser, editUser: (id: string) => void }) {

  return <tr>
    <td className="whitespace-nowrap pr-3 py-4 text-lg text-white">{user.name}</td>
    <td className="whitespace-nowrap pr-3 py-4 text-lg text-white">{user.email}</td>
    <td className="whitespace-nowrap pr-3 py-4 text-lg text-white">{user.role}</td>
    <td
      className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right items-end justify-end text-sm font-medium sm:pr-0">
      <button className="text-stone-400 hover:text-stone-300" onClick={() => editUser(user.id)}>
        Edit
      </button>
    </td>
  </tr>;
}

export function UsersTable({data, navigateTo}: { data: ManagedUser[], navigateTo: (userId: string) => void }) {
  return (
    <div className="mt-4">
      <table className="min-w-full divide-y divide-stone-300">
        <thead>
        <tr>
          {['Name', 'Email', 'Role'].map(header => {
            return <th className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-white sm:pl-0">
              <span>{header}</span>
            </th>
          })}
        </tr>
        </thead>
        <tbody className='divide-y divide-stone-300'>
        {
          data.map(user =>
            <UserRow
              user={user}
              editUser={(userId) => navigateTo(userId)}
            />
          )}
        </tbody>
      </table>
    </div>
  )
}

