import {Chart} from '../../../components/Chart/Chart';
import {useState} from 'react';
import {getStatusIcon} from "../../../components/Room/StatusIcons/GetStatusIcon";
import {DailySummary} from "../DailySummary";
import {SettingsIcon} from "../../../components/Icons/SettingsIcon";
import {NavigateBackButton} from "../../../components/Buttons/NavigateBackButton";
import {TwoColumns} from "../../../components/Layouts/TwoColumns";
import {StoneBox} from "../../../components/Layouts/StoneBox";
import {ProtectedView} from '../../../components/Layouts/ProtectedView';
import {IResidentProfile} from '../../RoomSettings/RoomSettingsView/Interfaces';
import {IChart, IDailySummary, IDetailedRoom} from "../../HomeArea/Interfaces";
import {ChartMetric} from "./ChartMetric";

export type ReportingPeriod = 'day' | 'week' | 'month';

interface IRoomViewProps {
  room: IDetailedRoom;
  resident: IResidentProfile | null;
  onHomeAreaButton: () => void;
  charts?: IChart[];
  onReportingPeriodChange: (period: 'day' | 'week' | 'month') => void;
  reportingPeriod: ReportingPeriod;
  onRoomSettingsClick: () => void;
  dailySummaryData: IDailySummary | null | undefined;
  dailySummaryDate: string;
  setDailySummaryDate: (date: string) => void;
}

export function ButtonGroup({options, currentValue, handleClick}: {
  options: { name: string, value: string }[],
  currentValue: string,
  handleClick: (type: string) => void
}) {
  return <div className="bg-stone-700/10 flex overflow-hidden border border-stone-500 rounded-xl">
    {options.map((option, index) => (
      <button key={index}
              className={`py-2 px-4 rounded-xl select-none ${option.value === currentValue ? 'bg-stone-700' : ''}`}
              onClick={() => handleClick(option.value)}>{option.name}</button>
    ))}
  </div>;
}

export const RoomView = (props: IRoomViewProps) => {
  const {
    room,
    resident,
    onHomeAreaButton,
    charts,
    onReportingPeriodChange,
    reportingPeriod,
    onRoomSettingsClick,
    dailySummaryData,
    dailySummaryDate,
    setDailySummaryDate,
  } = props;
  const [chartType, setChartType] = useState<'line' | 'bar'>('line')

  const chartTypeOptions = [
    {
      name: 'Line',
      value: 'line'
    },
    {
      name: 'Bar',
      value: 'bar'
    },
  ];

  const reportingPeriodOptions = [
    {
      name: 'Day',
      value: 'day'
    },
    {
      name: 'Week',
      value: 'week'
    },
    {
      name: 'Month',
      value: 'month'
    },
  ];

  const getStatusBarClasses = () => {
    let statusBarClasses = "flex-1 rounded-xl grid grid-cols-4 sm:grid-cols-6 justify-between items-center gap-3 p-4 sm:p-2 border text-center";
    if (room.alert) {
      statusBarClasses += " bg-red-500 border-red-900 text-red-50"
    } else {
      statusBarClasses += " bg-stone-700 border-stone-500"
    }
    return statusBarClasses;
  }

  const side = <div className="flex flex-col gap-4">
    <StoneBox>
      <NavigateBackButton onClick={onHomeAreaButton} label="Back to Home Area"/>
      <hr className='mb-4'/>
      <p className="text-md opacity-50">Home Area</p>
      <p className='text-2xl'>{room.homeArea.name}</p>
    </StoneBox>

    <DailySummary data={dailySummaryData} date={dailySummaryDate} setDate={setDailySummaryDate}/>
  </div>

  const main = <div className="flex-1 h-full flex flex-col">
    <div className="flex mb-4 gap-2 flex-col 2xl:flex-row">
      <div className={getStatusBarClasses()}>
        <h1 className="whitespace-nowrap text-left">{room.display_name}</h1>
        <div
          className="whitespace-nowrap text-right col-span-3 sm:col-span-1 sm:text-left">{room.status === 'vacant' ? 'Vacant Room' : resident?.firstName + ' ' + resident?.lastName}</div>
        <div className="flex flex-row justify-start sm:justify-end items-center col-span-2 sm:col-span-4">
          <div className="flex-none h-8 w-8 mr-2">
            {getStatusIcon(room.status)}
          </div>
          <div className="capitalize whitespace-nowrap">
            {room.status ? room.status.replaceAll('-', ' ') : 'Unknown'}
          </div>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row gap-2 justify-between">
        {
          (charts && charts.length > 0) && <div className="flex flex-row justify-between gap-2">
                <ButtonGroup options={chartTypeOptions}
                             currentValue={chartType}
                             handleClick={(type) => setChartType(type as "line" | "bar")}
                />
                <ButtonGroup options={reportingPeriodOptions}
                             currentValue={reportingPeriod}
                             handleClick={(value) => onReportingPeriodChange(value as ReportingPeriod)}
                />
            </div>
        }


        <ProtectedView roles={['admin', 'manager']}>
          {
            room.status !== 'vacant' && <div className="h-full">
                  <button
                      className="h-full py-2 px-4 select-none rounded-xl bg-stone-700 flex justify-between items-center"
                      onClick={onRoomSettingsClick}
                  >
                      <SettingsIcon/>
                      <span className="ml-2">Settings</span>
                  </button>
              </div>
          }

        </ProtectedView>
      </div>
    </div>

    <div className="flex flex-1 overflow-y-auto">
      <div className='flex min-h-min flex-col w-full'>
        {
          (charts && charts.length > 0)
            ? charts.map((chart, chartIndex) => (
              <div
                className="flex flex-col-reverse justify-between bg-stone-700 border border-stone-500 rounded-xl mb-4 sm:flex-row h-[36rem] sm:h-auto">
                <div
                  className="flex-1 p-2 bg-stone-800/50 rounded-b-xl sm:rounded-l-xl sm:rounded-b-none h-96 w-full sm:w-96 sm:p-6">
                  <Chart chartType={chartType} data={chart.graph}/>
                </div>
                <div className="w-full sm:pt-8 p-4 sm:w-80" key={chartIndex}>
                  <div className='text-4xl font-medium mb-2 sm:mb-6'>{chart.title}</div>
                  {
                    chart.metrics.map((metric, metricIndex) => (
                      <ChartMetric key={metricIndex} classes={metricIndex === 0 ? "text-[#81B3DF]" : "text-[#78ef78]"}
                                   metric={metric}/>
                    ))
                  }
                </div>
              </div>))
            : <StoneBox>
              <div className="flex flex-1 items-center justify-center text-white text-2xl h-48">
                No chart data available.
              </div>
            </StoneBox>
        }
      </div>
    </div>
  </div>


  return (
    <TwoColumns side={side} main={main}/>
  )
}
