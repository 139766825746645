import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import HomeAreaPage from './pages/HomeArea';
import RoomPage from './pages/RoomPage';
import LoginPage from './pages/Login';
import FacilitySettingsPage from './pages/FacilitySettings';
import RoomSettingsPage from './pages/RoomSettings';
import DeviceManagement from './pages/DeviceManagement';
import {UserManagement} from './pages/UserManagement';
import {CreateDevice} from './pages/CreateDevice';
import {EditDevice} from "./pages/EditDevice";
import WelcomePage from "./pages/Welcome";
import {AuthProvider} from "./context/AuthContext";
import {ProtectedRoute} from "./pages/ProtectedRoute/ProtectedRoute";
import {StandardLayout} from './components/Layouts/StandardLayout';
import {MessagePage} from "./components/Layouts/MessagePage";
import {ActivateDevice} from './pages/ActivateDevice';
import {AutoLogout} from "./components/AutoLogout/AutoLogout";
import {CreateUser} from "./pages/CreateUser";
import {EditUser} from "./pages/EditUser/EditUser";
import {EditProfile} from "./pages/EditProfile/EditProfile";
import {IncidentSummaryPage} from "./pages/IncidentSummaryPage";

function App() {
  return (
    <AuthProvider>
      <AutoLogout>
        <div className="h-screen bg-stone-900">
          <BrowserRouter>
            <StandardLayout>
              <Routes>
                <Route path="/" element={<WelcomePage/>}/>
                <Route path="/home-areas/:homeAreaId" element={
                  <ProtectedRoute roles={['admin', 'manager', 'viewer']}>
                    <HomeAreaPage/>
                  </ProtectedRoute>}
                />
                <Route path="/rooms/:bedId"
                       element={<ProtectedRoute roles={['admin', 'manager', 'viewer']}><RoomPage/></ProtectedRoute>}/>

                <Route path="/login" element={<LoginPage/>}/>

                <Route path="/settings"
                       element={<ProtectedRoute roles={['admin', 'manager']}><FacilitySettingsPage/></ProtectedRoute>}/>
                <Route path="/settings/users"
                       element={<ProtectedRoute roles={['admin']}><UserManagement/></ProtectedRoute>}/>
                <Route path="/settings/users/new"
                       element={<ProtectedRoute roles={['admin']}><CreateUser/></ProtectedRoute>}/>
                <Route path="/settings/users/:userId/edit"
                       element={<ProtectedRoute roles={['admin']}><EditUser/></ProtectedRoute>}/>
                <Route path="/settings/devices"
                       element={<ProtectedRoute roles={['admin']}><DeviceManagement/></ProtectedRoute>}/>
                <Route path="/settings/devices/new"
                       element={<ProtectedRoute roles={['admin']}><CreateDevice/></ProtectedRoute>}/>
                <Route path="/settings/devices/:deviceId/edit"
                       element={<ProtectedRoute roles={['admin']}><EditDevice/></ProtectedRoute>}/>
                <Route path="/settings/devices/:deviceId/activate"
                       element={<ProtectedRoute roles={['admin']}><ActivateDevice/></ProtectedRoute>}/>
                <Route path="/rooms/:roomId/settings"
                       element={<ProtectedRoute roles={['admin', 'manager']}><RoomSettingsPage/></ProtectedRoute>}/>
                {/*<Route path="/rooms/:roomId/settings/transfer"*/}
                {/*       element={<ProtectedRoute roles={['admin', 'manager']}><TransferPage/></ProtectedRoute>}/>*/}
                {/*<Route path="/rooms/:roomId/settings/absence"*/}
                {/*       element={<ProtectedRoute roles={['admin', 'manager']}><ManageAbsence/></ProtectedRoute>}/>*/}
                <Route path="/profile"
                       element={<ProtectedRoute
                         roles={['admin', 'viewer', 'manager']}><EditProfile/></ProtectedRoute>}/>
                <Route path="/*" element={<ProtectedRoute roles={['admin', 'viewer', 'manager']}><MessagePage
                  message="Page not found."/></ProtectedRoute>}/>

                <Route path="home-areas/:homeAreaId/incident-summary"
                       element={<ProtectedRoute roles={['admin', 'manager']}><IncidentSummaryPage/></ProtectedRoute>}/>
              </Routes>
            </StandardLayout>
          </BrowserRouter>
        </div>
      </AutoLogout>
    </AuthProvider>
  );
}

export default App;
