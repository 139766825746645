import {IChartMetric} from "../../HomeArea/Interfaces";

export function ChartMetric(props: { classes: string, metric: IChartMetric }) {
  return <div className="mb-2 sm:mb-4">
    <div
      className={"text-2xl font-semibold drop-shadow-sm " + props.classes}>{props.metric.label}
    </div>
    <div className="text-4xl font-medium">
      {props.metric.value}<span className="text-xl ml-2">{props.metric.units}</span>
    </div>
  </div>;
}