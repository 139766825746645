import {useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {getActivationCode, getDevice} from '../../services/httpService';
import {IDevice} from '../DeviceManagement';
import {StoneBox} from '../../components/Layouts/StoneBox';
import {NavigateBackButton} from '../../components/Buttons/NavigateBackButton';
import {InfoMessage} from '../../components/FormElements/InfoMessage';

function ActivationTokenInfo(props: { activationCode: string }) {
  return <div>
    <div className="p-4 text-3xl bg-stone-800 mb-2">
      {props.activationCode}
    </div>
    <InfoMessage message="Enter this code in the ElephasCare iOS application to activate it, then refresh this page."/>
  </div>;
}

export const ActivateDevice = () => {
  const navigate = useNavigate();
  const {deviceId} = useParams() as { deviceId: string }
  const [device, setDevice] = useState<IDevice | null>(null)
  const [activationCode, setActivationCode] = useState<string | null>(null)

  useEffect(() => {
    const fetchDevice = async () => {
      const res = await getDevice(deviceId)
      setDevice(res)
    }

    fetchDevice().catch(e => console.error(e))
  }, [deviceId])

  const handleRevealActivationCode = async () => {
    if (!device) {
      return
    }
    const res = await getActivationCode(device.id);

    setActivationCode(res.activationCode)

  };
  return <div className="flex justify-center mt-16">
    <div
      className="w-3/4 md:w-1/2 lg:w-1/3 text-white flex flex-col justify-start">
      <StoneBox>
        <NavigateBackButton onClick={() => navigate('/settings/devices')} label={"Back to Device Management"}/>
        <hr className="mb-4"/>
        <h1 className="text-3xl">
          Activate Device
        </h1>
        <div className="mt-2 mb-3">
          <dl>
            <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">Device Name:</dt>
              <dd className="mt-1 text-sm leading-6 text-white sm:col-span-2 sm:mt-0">{device?.name}</dd>
            </div>
            <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">Home Area:</dt>
              <dd className="mt-1 text-sm leading-6 text-white sm:col-span-2 sm:mt-0">{device?.homeArea.name}</dd>
            </div>
            <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">Access Level:</dt>
              <dd className="mt-1 text-sm leading-6 text-white sm:col-span-2 sm:mt-0">{device?.accessLevel}</dd>
            </div>
            <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">Status:</dt>
              <dd className="mt-1 text-sm leading-6 text-white sm:col-span-2 sm:mt-0">
                {device?.deviceId
                  ? <span
                    className="inline-flex items-center rounded-md bg-green-500/10 px-2 py-1 text-xs font-medium text-green-400 ring-1 ring-inset ring-green-500/20">
                      Activated
                    </span>
                  : <span
                    className="inline-flex items-center rounded-md bg-yellow-400/10 px-2 py-1 text-xs font-medium text-yellow-500 ring-1 ring-inset ring-yellow-400/20">
                      Not Activated
                    </span>
                }
              </dd>
            </div>
          </dl>
        </div>
        <div className="text-center">
          {
            activationCode
              ? <ActivationTokenInfo activationCode={activationCode}/>
              : <button
                className="rounded-md bg-indigo-500 text-2xl px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                onClick={handleRevealActivationCode}>Reveal Activation Code</button>
          }
        </div>
      </StoneBox>
    </div>
  </div>
}
