import RoomPanel from "../../../components/Room/RoomPanel";
import {StoneBox} from "../../../components/Layouts/StoneBox";
import LabelledDropdown from "../../../components/FormElements/LabelledDropdown";
import {HomeAreaProps} from "./HomeAreaView";
import {useNavigate} from "react-router-dom";
import {ProtectedView} from "../../../components/Layouts/ProtectedView";

export default function useHomeAreaView(props: HomeAreaProps) {
  const {homeArea, rooms, homeAreas, onHomeAreaChange} = props
  const navigate = useNavigate();
  const handleRoomClick = (roomNumber: string) => {
    navigate(`/rooms/${roomNumber}`)
  }

  const handleViewIncidentClick = () => {
    navigate(`/home-areas/${homeArea.id}/incident-summary`)
  }

  const currentTime = new Date().toTimeString().substring(0, 8);
  const sideContent = <>
    <StoneBox>
      <div className='flex flex-row justify-between lg:flex-col gap-4'>
        <div className='w-36'>
          <LabelledDropdown label="Home Area:" value={homeArea.id} options={homeAreas.map((area) => {
            return {name: area.name, value: area.id}
          })} setValue={onHomeAreaChange}/>
        </div>

        <div>
          <h2 className='text-md font-semibold opacity-50'>Reporting Time: </h2>
          <div className='text-white text-lg font-semibold mb-2 text-end lg:text-left'>{currentTime}</div>
        </div>
        <ProtectedView roles={['admin', 'manager']}>
          {
            <button className="text-left" onClick={handleViewIncidentClick}>View Incidents</button>
          }
        </ProtectedView>
      </div>
    </StoneBox>
  </>


  const mainContent = <div className='grid grid-cols-2 sm:grid-cols-5 xl:grid-cols-8 gap-4 flex-1'>
    {
      rooms.map((room) => {
        return <RoomPanel key={room.id} onClick={() => handleRoomClick(room.id)} room={room}/>
      })
    }
  </div>
  return {sideContent, mainContent};
}