import React from 'react';

type LabelledDropdownProps = {
  label: string;
  value: number;
  options: Array<{ name: string, value: number }>
  setValue: (value: number) => void;
  disabled?: boolean
}

function LabelledNumberDropdown(props: LabelledDropdownProps) {
  const {
    label,
    value,
    setValue,
    options,
  } = props;

  const disabled = props.disabled || false;

  const valueInOptions = options.map(option => {
    return option.value;
  }).find(option => option === value);
  const labelClasses = disabled ? 'text-gray-400' : ''
  return (
    <div>
      <label className={labelClasses}>
        {label}
        <select
          className='form-select block w-full rounded-md border-0 py-2 px-2 text-stone-900 shadow-sm ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6'
          value={value}
          onChange={(e) => setValue(Number.parseFloat(e.target.value))}
          disabled={disabled}
        >
          {!valueInOptions && <option className='text-black' value={undefined}>
              -- Select Value
          </option>
          }
          {options.map((option, index) => {
            return <option className='text-black' key={index} value={option.value}>
              {option.name}
            </option>
          })}
        </select>
      </label>
      {disabled && <p className="text-xs text-gray-400">(Available Soon)</p>}
    </div>
  );
}

export default LabelledNumberDropdown;
