import {TwoColumns} from "../../../components/Layouts/TwoColumns";
import useHomeAreaView from "./HomeAreaView.hooks";
import {IBaseRoom, IHomeArea} from "../Interfaces";


export interface HomeAreaProps {
  homeArea: IHomeArea
  rooms: IBaseRoom[]
  homeAreas: IHomeArea[],
  onHomeAreaChange: (homeAreaId: string) => void
}

export const HomeAreaView = (props: HomeAreaProps) => {


  const {sideContent, mainContent} = useHomeAreaView(props);

  return <TwoColumns side={sideContent} main={mainContent}/>

}
