import {EditUserForm} from "./EditUserForm";
import {useEffect, useState} from "react";
import {ManagedUser} from "../UserManagement";
import {useNavigate, useParams} from "react-router-dom";
import {deleteUser, getUser, updateUser} from "../../services/httpService";
import {Role} from "../../context/IAuthContext";
import {Spinner} from "../../components/Icons/Spinner";
import {useNotification} from "../../context/NotificationContext";

export const EditUser = () => {
  const navigate = useNavigate();
  const {userId} = useParams() as { userId: string }
  const [isLoading, setIsLoading] = useState(true);
  const [UserData, setUserData] = useState<ManagedUser | null>(null);
  const {showSuccess, showError} = useNotification();
  const [errorsList, setErrorsList] = useState<Record<string, string[]>>({});

  useEffect(() => {
    (async () => {
      try {
        const UserDataResponse = await getUser(userId)
        setUserData(UserDataResponse);
        setIsLoading(false)
      } catch (e) {
        console.error(e)
      }

    })()
  }, [])

  const handleUpdateUser = async (name: string, email: string, role: string, password?: string) => {
    setErrorsList({});
    try {
      const data = password ? {name, email, role, password} : {name, email, role}
      const res = await updateUser(userId, data)
      showSuccess(res.message)

      setUserData((previousUser) => {
        if (!previousUser) {
          return null;
        }
        return {
          ...previousUser,
          name: name,
          email: email,
          role: role as Role,
        }
      })

    } catch (e: any) {
      if (e.response?.status !== 422) {
        showError(e.response?.data?.message ?? "Something went wrong");
      }
      if (e.response?.data?.errors) {
        setErrorsList(e.response?.data?.errors);
      }
    }
  }

  const handleDelete = async () => {
    if (window.confirm('Warning: Do you really want to delete this User?')) {
      try {
        await deleteUser(userId);
        showSuccess('Successfully deleted User');
        navigate("/settings/users");
      } catch (e: any) {
      }
    }
  }

  if (isLoading || !UserData) {
    return <div className="flex justify-center h-full items-center">
      <Spinner/>
    </div>
  }

  return (
    <div className="flex justify-center  h-full">
      <div
        className="w-1/2 lg:w-1/5 text-white flex flex-col justify-start gap-4">
        <EditUserForm
          navigateBack={() => navigate('/settings/users')}
          onDeleteClick={handleDelete}
          onSubmitClick={handleUpdateUser}
          userData={UserData}
          errorsList={errorsList}
        />
      </div>
    </div>
  )
}
