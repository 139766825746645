import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";

const WelcomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('login')
  }, [])
  return <div>welcome!</div>
}

export default WelcomePage
