import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import {AuthContextType, AuthProviderProps, User} from "./IAuthContext";
import {loginDetails} from "../services/httpService";

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const token = localStorage.getItem('bearer_token') || null;
  const name = localStorage.getItem('name') || null;
  const email = localStorage.getItem('email') || null;
  const role = localStorage.getItem('role') || null;
  const allowedInactivityTime = localStorage.getItem('allowedInactivityTime')
    ? parseInt(localStorage.getItem('allowedInactivityTime') as string)
    : undefined
  const intervalRef = useRef<NodeJS.Timer | undefined>();

  useEffect(() => {

    logoutWatcher();

  }, [])

  const [user, setUser] = useState<User | null>(() => {
    if (token && name && email && role) {
      return {
        token: token,
        name,
        email,
        role,
        allowedInactivityTime
      } as User
    }
    return null
  });

  const login = async (username: string, password: string) => {
    const loginData = await loginDetails(username, password);
    localStorage.setItem('bearer_token', loginData.token);
    localStorage.setItem('name', loginData.name);
    localStorage.setItem('email', loginData.email);
    localStorage.setItem('role', loginData.role);

    const allowedInactivityTimeAsInteger = Number.isInteger(loginData.allowedInactivityTime) ? loginData.allowedInactivityTime : undefined;
    const allowedInactivityTimeAsString = Number.isInteger(loginData.allowedInactivityTime) ? loginData.allowedInactivityTime.toString() : undefined;
    localStorage.setItem('allowedInactivityTime', allowedInactivityTimeAsString);

    setUser({
      name: loginData.name,
      email: loginData.email,
      token: loginData.token,
      role: loginData.role,
      allowedInactivityTime: allowedInactivityTimeAsInteger
    })

    logoutWatcher();
  }

  const logoutWatcher = () => {

    if (!localStorage.getItem('bearer_token')) {
      return;
    }

    if (intervalRef.current) {
      return;
    }

    intervalRef.current = setInterval(() => {

      const bearerToken = localStorage.getItem('bearer_token')
      if (!bearerToken) {
        clearInterval(intervalRef.current);
        logout();
      }
    }, 1000);
  }

  const logout = () => {
    localStorage.removeItem('bearer_token');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('role');
    localStorage.removeItem('allowedInactivityTime');
    setUser(null);
    console.log('User has been logged out')
    window.location.reload();
    console.log('Browser has been refreshed')
  }

  const value: AuthContextType = { user, setUser, login, logout };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

