import { ReactNode } from 'react';
import { Role } from '../../context/IAuthContext';
import { useAuth } from '../../context/AuthContext';

export const ProtectedView = ({children, roles}: { children: ReactNode, roles: Array<Role> }) => {
  const {user} = useAuth();

  if (!user) {
    return <></>
  }

  if (!roles.includes(user.role)) {
    return <></>
  }
  
  return <>{children}</>
}
