import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {IBaseRoom, IHomeArea} from "./Interfaces";
import {getHomeAreaRooms, getHomeAreas} from "../../services/httpService";

export function useHomeAreaPage() {
  let {homeAreaId} = useParams() as { homeAreaId: string };
  const [rooms, setRooms] = useState<IBaseRoom[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [homeAreas, setHomeAreas] = useState<IHomeArea[]>([]);
  const [currentHomeArea, setCurrentHomeArea] = useState<IHomeArea | null | undefined>(null);


  const loadRooms = async (homeAreaId: string) => {
    try {
      const roomsData = await getHomeAreaRooms(homeAreaId);
      setRooms(roomsData);
    } catch (e) {
      console.log({e})
    }
  };
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const homeAreasData = await getHomeAreas();
        setHomeAreas(homeAreasData)
        const homeArea = homeAreasData.find((homeArea: IHomeArea) => homeArea.id === homeAreaId);

        if (!homeArea) {
          setLoading(false);
          return;
        }
        setCurrentHomeArea(homeArea);
      } catch (e) {
        console.log({e})
      }
      await loadRooms(homeAreaId);
      setLoading(false);
    })();
    const setter = setInterval(() => loadRooms(homeAreaId), 60 * 1000)
    return () => clearInterval(setter)
  }, [homeAreaId]);
  return {rooms, loading, homeAreas, currentHomeArea};
}