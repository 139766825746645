interface TwoColumnsProps {
  side: JSX.Element;
  main: JSX.Element;
}

export const TwoColumns = ({ side, main }: TwoColumnsProps) => {
  return (
    <div className='text-white flex flex-col lg:flex-row gap-6 pb-10'>
      <div className="w-full lg:w-80 lg:min-w-80">
        {side}
      </div>
      {main}
    </div>
  )
}
