import {TopBar} from '../TopBar/TopBar';
import {ReactNode} from 'react';
import {useAuth} from '../../context/AuthContext';

interface StandardLayoutProps {
  children: ReactNode;
}

function Footer() {
  return <footer className="text-center text-white text-sm font-medium pb-4 mt-4 flex flex-col justify-center gap-3">
    <div className="flex flex-row justify-center gap-3">
      <a className="hover:text-blue-500 hover:underline" href="https://support.elephascare.com/support/tickets/new">Contact
        Support</a>
      &#x2022;
      <a className="hover:text-blue-500 hover:underline" href="https://www.elephascare.com/privacy" target="_blank"
         rel="noreferrer">Privacy Policy</a>
    </div>
    <div>
      <p className="text-gray-400 text-xs">
        ElephasCare Version 1.4 © 2024 by Gold Sentintel.</p>
    </div>
  </footer>;
}


export function StandardLayout({children}: StandardLayoutProps) {
  const {user} = useAuth();

  if (!user) {
    return (<>
      <div className="h-full flex flex-col px-6">
        {children}
        <Footer/>
      </div>
    </>)
  }

  return (<>
    <div className="h-full flex flex-col px-6">
      <TopBar/>
      {children}
      <Footer/>
    </div>
  </>)
}
