import {useEffect, useState} from "react";
import {StoneBox} from "../../components/Layouts/StoneBox";
import {TwoColumns} from "../../components/Layouts/TwoColumns";
import {getHomeAreas, getUsers} from "../../services/httpService";
import {UsersTable} from "./UsersTable";
import {useNavigate} from "react-router-dom";
import {NavigateBackButton} from "../../components/Buttons/NavigateBackButton";
import {ProtectedView} from "../../components/Layouts/ProtectedView";

export interface ManagedUser {
  id: string,
  name: string,
  email: string,
  role: string,
}


export const UserManagement = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<Array<ManagedUser> | null>(null);

  const fetchUsers = (async () => {
    try {
      const result = await getUsers();
      setUsers(result)
    } catch (e) {

    }
  });

  useEffect(() => {
    fetchUsers().catch();
  }, []);

  const handleEditClick = (userId: string) => {
    navigate(`/settings/users/${userId}/edit`)
  }

  const handleBackToHomeArea = async () => {
    const homeAreas = await getHomeAreas();
    const firstHomeArea = homeAreas[0].id
    navigate(`/home-areas/${firstHomeArea}`);
  }

  const sideContent = <StoneBox>
    <div className="flex flex-col">
      <NavigateBackButton onClick={handleBackToHomeArea} label='Back to Home View'/>
      <hr className="mb-4"/>
      <button
        className="text-left hover:text-blue-500" onClick={() => navigate('/settings')}>
        Facility Settings
      </button>
      <ProtectedView roles={['admin']}>
        <button
          className="text-left hover:text-blue-500" onClick={() => navigate('/settings/devices')}>
          Device Settings
        </button>
      </ProtectedView>
      <ProtectedView roles={['admin']}>
        <button
          className="text-left hover:text-blue-500" onClick={() => navigate('/settings/users')}>
          User Settings
        </button>
      </ProtectedView>
    </div>

  </StoneBox>

  const mainContent = <StoneBox>
    <div className="flex-1 flex flex-col justify-start">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl text-white">Manage Users</h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block select-none rounded-md bg-stone-500 px-3 py-2 text-center text-sm font-semibold text-white hover:bg-stone-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-500"
            onClick={() => navigate('/settings/users/new')}
          >
            Add user
          </button>
        </div>
      </div>
    </div>
    <div>
      {users && <UsersTable data={users} navigateTo={handleEditClick}/>}
    </div>
  </StoneBox>

  return (
    <TwoColumns side={sideContent} main={mainContent}/>
  )
}