import LabelledTextInput from "../../components/FormElements/LabelledTextInput";
import LabelledDropdown from "../../components/FormElements/LabelledDropdown";
import SubmitButton from "../../components/FormElements/SubmitButton";
import {StoneBox} from "../../components/Layouts/StoneBox";
import {ManagedUser} from "../UserManagement";
import {useMemo, useReducer} from "react";
import {NavigateBackButton} from "../../components/Buttons/NavigateBackButton";
import _ from "lodash";
import LabelledPasswordInput from "../../components/FormElements/LabelledPasswordInput";

interface IEditUserFormProps {
  navigateBack: () => void,
  // allHomeAreas: IHomeArea[],
  onSubmitClick: (name: string, email: string, role: string, password: string) => Promise<void>,
  onDeleteClick: () => Promise<void>,
  userData: ManagedUser,
  errorsList: Record<string, string[]>
}

export type ManagedUserWithPassword = ManagedUser & { password: string }

const reducer = (state: ManagedUserWithPassword, action: { type: string, value: string, key: string }) => {
  switch (action.type) {
    case 'onChange':
      return {...state, [action.key]: action.value}
    default:
      return state;
  }
}

const shouldUpdateButtonBeDisabled = (initialState: ManagedUserWithPassword, currentState: ManagedUserWithPassword): boolean => {
  return _.isEqual(initialState, currentState)
    || currentState.name === ''
    || currentState.email === ''
}


export function EditUserForm(props: IEditUserFormProps) {
  const {
    userData,
    navigateBack,
    onSubmitClick,
    onDeleteClick,
    errorsList,
  } = props;
  const initialState = useMemo<ManagedUserWithPassword>(() => {
    return {
      id: userData.id,
      name: userData.name,
      email: userData.email,
      password: '',
      role: userData.role,
    } as ManagedUserWithPassword
  }, [userData])
  const [state, dispatch] = useReducer(reducer, initialState);
  const isUpdateButtonDisabled = shouldUpdateButtonBeDisabled(initialState, state);

  const handleInputChange = (value: string, name: keyof ManagedUserWithPassword) => {
    dispatch({type: 'onChange', key: name, value: value});
  }
  const handleSubmit = () => {
    onSubmitClick(state.name, state.email, state.role, state.password);
    if (state.password) {
      state.password = '';
    }
  }

  return <StoneBox>
    <div className="flex flex-row align-middle justify-between">
    <NavigateBackButton onClick={navigateBack} label={'Back to User Management'}/>
      <button
        className="mb-2 px-4 py-2 rounded text-red-400 select-none hover:bg-red-400 hover:text-red-800"
        onClick={onDeleteClick}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
             stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
        </svg>
        <span className="sr-only">Delete User</span>
      </button>
    </div>
    <hr className="mb-4"/>
    <h1 className="text-3xl mb-4">
      Edit User
    </h1>
    <div className="flex flex-col w-full space-between gap-4">
      <div className="flex-1 text-md">
        <LabelledTextInput
          label="Name"
          value={state.name}
          setValue={(value) => handleInputChange(value, 'name')}
          errors={errorsList.name}
        />
      </div>
      <div className="flex-1 text-md">
        <LabelledTextInput
          label="Email"
          value={state.email}
          setValue={(value) => handleInputChange(value, 'email')}
          errors={errorsList.email}
        />
      </div>
      <div className="flex-1 text-md">
        <LabelledPasswordInput
          label="Password"
          value={state.password}
          placeholder={'Change Password (Optional)'}
          setValue={(value) => handleInputChange(value, 'password')}
          errors={errorsList.password}
        />
      </div>
      <div className="flex-1 text-md">
        <LabelledDropdown
          label="Role"
          value={state.role}
          options={[
            {
              name: 'Viewer',
              value: 'viewer',
            },
            {
              name: 'Manager',
              value: 'manager',
            },
            {
              name: 'Admin',
              value: 'admin',
            },
          ]}
          setValue={(value) => handleInputChange(value, 'role')}
        />
      </div>
    </div>
    <div className="pt-4 pb-4 flex flex-row gap-4 justify-center">
        <SubmitButton
          name={'Update User'}
          onClick={handleSubmit}
          isDisabled={isUpdateButtonDisabled}
        />
      </div>
  </StoneBox>

}
