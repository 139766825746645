import {useEffect, useState} from "react";
import {getIncidentChart} from "../../../services/httpService";
import {useParams} from "react-router-dom";
import {ButtonGroup, ReportingPeriod} from "../../RoomPage/RoomView/RoomView";
import {SettingsIcon} from "../../../components/Icons/SettingsIcon";
import {Spinner} from "../../../components/Icons/Spinner";
import {FluidChart, IChartGraphData} from "../../../components/Chart/FluidChart";

interface IncidentChartProps {
  showIncidentTable: () => void;
}

export function IncidentChart({showIncidentTable}:IncidentChartProps) {
  const {homeAreaId} = useParams() as { homeAreaId: string };
  const [reportingPeriod, setReportingPeriod] = useState<ReportingPeriod>('day');
  const [graphType, setGraphType] = useState<'line' | 'bar'>('bar');
  const [incidentChartData, setIncidentChartData] = useState<IChartGraphData | undefined>();

  const handleReportingPeriod = (reportingPeriodDate: ReportingPeriod): void => {
    setReportingPeriod(reportingPeriodDate);
  }


  useEffect(() => {
    const fetchIncidentChart = async () => {
      const incidentChart = await getIncidentChart(homeAreaId, reportingPeriod);
      setIncidentChartData(incidentChart);
    }
    fetchIncidentChart().catch();
  }, [homeAreaId, reportingPeriod]);

  const reportingPeriodOption = [
    {name: 'Day', value: 'day'},
    {name: 'Week', value: 'week'},
    {name: 'Month', value: 'month'},
  ];
  const graphOption = [
    {name: 'Line', value: 'line'},
    {name: 'Bar', value: 'bar'},
  ];

  return <div className="flex-1 h-full flex flex-col">
    <div className="flex mb-4 gap-2 justify-end w-full ">
      <button
        className="h-full py-2 px-4 select-none rounded-xl bg-stone-700 flex justify-between items-center"
        onClick={showIncidentTable}
      >
        <SettingsIcon/>
        <span className="ml-2">View Log</span>
      </button>
      <div className="flex-1"></div>
      <ButtonGroup
        options={graphOption}
        currentValue={graphType}
        handleClick={(type) => setGraphType(type as "line" | "bar")}
      />
      <ButtonGroup
        options={reportingPeriodOption}
        currentValue={reportingPeriod}
        handleClick={(reportingPeriod) => handleReportingPeriod(reportingPeriod as ReportingPeriod)}
      />
    </div>
    {
      incidentChartData && Object.keys(incidentChartData).length > 0
        ?
        <div
          className="flex border bg-[#363330] border-stone-500 rounded-xl sm:flex-row h-full">
          <div
            className="flex-1 rounded-b-xl sm:rounded-l-xl sm:rounded-b-none h-full w-full sm:w-96 sm:p-6">
            <FluidChart chartType={graphType} data={incidentChartData}/>
            </div>
        </div>
      :
        <div className="w-full h-full flex justify-center items-center"><Spinner/></div>
    }
  </div>;
}