import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import {getCurrentTime} from "./GetCurrentTime";

dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);


function Badge({text, title, extraClass}: { text: string, title: string, extraClass: string }) {
  return <span
    role="status"
    title={title}
    className={`whitespace-nowrap px-3 py-3 text-lg font-semibold bg-opacity-80 rounded-md text-white ${extraClass}`}
  >{text}</span>;
}


export function LatestActivityBadge(props: { date: string }) {
  const differenceInMilliseconds = getCurrentTime() - new Date(props.date).getTime();
  const numberOfHoursAgo = differenceInMilliseconds / (1000 * 60 * 60)

  const differenceInTime = dayjs(props.date).fromNow();
  const localTime = dayjs(props.date).format('LLL');
  if (numberOfHoursAgo > 24) {
    return <Badge extraClass="bg-red-400 " title={localTime} text={differenceInTime}/>;
  }

  if (numberOfHoursAgo > 9) {
    return <Badge extraClass="bg-yellow-400 " title={localTime} text={differenceInTime}/>;
  }

  return <Badge extraClass="bg-green-400 " title={localTime} text={differenceInTime}/>;
}