import {SettingsIcon} from "../../../components/Icons/SettingsIcon";
import {useEffect, useState} from "react";
import {getAllIncidents} from "../../../services/httpService";
import {useParams} from "react-router-dom";
import {Spinner} from "../../../components/Icons/Spinner";
import {ButtonGroup} from "../../RoomPage/RoomView/RoomView";

interface IncidentTableProps {
  showIncidentChart: () => void
}

interface Incident {
  id: string
  type: string
  bed_name: string
  status: string
  time: string
  responding_device_name: string | null
  response_time: string | null
  arrival_time: string | null
}

const alertTypeOption = [
  {name: 'All', value: ''},
  {name: 'Fall', value: 'Fall'},
  {name: 'Bed exit', value: 'Bed exit'},
  {name: 'Out of bed', value: 'Out of bed'},
  {name: 'Out of room', value: 'Out of room'},
  {name: 'No movement', value: 'No movement'},
];

type AlertType = '' |
  'Fall' |
  'Bed exit' |
  'Out of bed' |
  'Out of room' |
  'No movement';

const statusOptions = [
  {name: 'All', value: ''},
  {name: 'Current', value: 'Current'},
  {name: 'Confirmed', value: 'Confirmed'},
  {name: 'False alarm', value: 'False alarm'},
];

type Status = '' | 'Current' | 'Confirmed' | 'False Alarm'

const daysOptions = [
  {name: '30 Days', value: '30'},
  {name: 'All Data', value: ''},
];

type DaysType = number | undefined

export function IncidentTable({showIncidentChart}: IncidentTableProps) {
  const {homeAreaId} = useParams() as { homeAreaId: string };
  const [incidents, setIncidents] = useState<Array<Incident>>([])
  const [loading, setLoading] = useState(false);
  const [alertTypeFilter, setAlertTypeFilter] = useState<AlertType>('');
  const [statusFilter, setStatusFilter] = useState<Status>('');
  const [daysToShow, setDaysToShow] = useState<number | undefined>(30);
  const [bedNameFilter, setBedNameFilter] = useState('');
  const [deviceNameFilter, setDeviceNameFilter] = useState('');

  const filteredIncidents = incidents.filter((incident) => {
    if (bedNameFilter) {
      if (!incident.bed_name.toLowerCase().startsWith(bedNameFilter.toLowerCase())) {
        return false;
      }
    }

    if (deviceNameFilter) {
      if (!incident.responding_device_name?.toLowerCase().startsWith(deviceNameFilter.toLowerCase())) {
        return false;
      }
    }

    if (alertTypeFilter) {
      if (incident.type !== alertTypeFilter) {
        return false;
      }
    }

    if (statusFilter) {
      if (incident.status !== statusFilter) {
        return false;
      }
    }

    return true;
  });

  useEffect(() => {
    const fetchIncidents = async () => {
      setLoading(true);
      const incidents = await getAllIncidents(homeAreaId, daysToShow);
      setIncidents(incidents)
      setLoading(false);
    }
    fetchIncidents().catch();
  }, [homeAreaId, daysToShow])

  return <div className="flex-1 h-full flex flex-col">
    <div className="flex mb-4 gap-2 justify-start w-full">
      <button
        className="h-full py-2 px-4 select-none rounded-xl bg-stone-700 flex justify-between items-center"
        onClick={showIncidentChart}
      >
        <SettingsIcon/>
        <span className="ml-2">View Chart</span>
      </button>
      <ButtonGroup
        options={daysOptions}
        currentValue={daysToShow?.toString() || ''}
        handleClick={(days) => setDaysToShow((days ? parseInt(days) : undefined))}
      />

      <ButtonGroup
        options={alertTypeOption}
        currentValue={alertTypeFilter}
        handleClick={(alertType) => setAlertTypeFilter(alertType as AlertType)}
      />

      <ButtonGroup
        options={statusOptions}
        currentValue={statusFilter}
        handleClick={(status) => setStatusFilter(status as Status)}
      />

      <input
        className={'block w-24 rounded-xl border border-stone-500 bg-stone-700/10 py-2 px-2 text-white shadow-sm placeholder:text-white sm:text-sm sm:leading-6'}
        placeholder='Room #'
        onChange={(e) => {
          setBedNameFilter(e.target.value)
        }}
        value={bedNameFilter}
      />

      <input
        className={'block w-24 rounded-xl border border-stone-500 bg-stone-700/10 py-2 px-2 text-white shadow-sm placeholder:text-white sm:text-sm sm:leading-6'}
        placeholder='Device'
        onChange={(e) => {
          setDeviceNameFilter(e.target.value)
        }}
        value={deviceNameFilter}
      />
    </div>
    <div>

    </div>
    {
      (!loading)
        ? <div className="rounded-lg overflow-hidden">
          <table className="w-full bg-stone-700">

            <thead className="h-12 text-left text-lg font-bold bg-stone-500">
          <tr>
            <th className="pl-8">Date</th>
            <th>Alert Type</th>
            <th>Room #</th>
            <th>Time to Acknowledge</th>
            <th>Time to Arrive</th>
            <th>Device</th>
            <th>Status</th>
          </tr>
          </thead>

            <tbody className="divide-y divide-stone-500">
          {
            filteredIncidents.map(incident => {
              return <tr key={incident.id} className="bg-stone-700">
                <td className="pl-8 py-2 text-lg">{incident.time}</td>
                <td className="font-bold">{incident.type}</td>
                <td>{incident.bed_name}</td>
                <td>{incident.response_time??"—"}</td>
                <td>{incident.arrival_time ?? "—"}</td>
                <td>{incident.responding_device_name??"—"}</td>
                <td>{incident.status}</td>
              </tr>
            })
          }
          </tbody>
        </table>
        </div>
        : <div className="w-full h-full flex justify-center items-center"><Spinner/></div>
    }

  </div>
}