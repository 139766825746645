import {ReactNode, useEffect, useMemo, useState} from "react";
import {useAuth} from "../../context/AuthContext";
import {User} from "../../context/IAuthContext";

interface IAutoLogoutProps {
  children: ReactNode;
}

const TEN_MINUTES = (1000 * 60) * 10;
const EXPIRE_TIME_KEY = 'expireTime';

function getAllowedInactivityTime(user: User | null): number {
  if (!user) {
    return TEN_MINUTES
  }

  if (!user.allowedInactivityTime) {
    return TEN_MINUTES
  }

  if (!Number.isInteger(user.allowedInactivityTime)) {
    return TEN_MINUTES
  }

  return user.allowedInactivityTime
}

export const AutoLogout = (props: IAutoLogoutProps) => {
  const {children} = props;
  const {logout, user} = useAuth();
  const allowedInactivityTime: number = useMemo(() => getAllowedInactivityTime(user), [user]);
  const [isMounted, setIsMounted] = useState(false);

  const verifyInitialInactivity = async () => {
    const localExpireTime = localStorage.getItem(EXPIRE_TIME_KEY)

    if (!localExpireTime) {
      setIsMounted(true);
      return;
    }

    if (Date.now() > parseInt(localExpireTime)) {
      logout();
      localStorage.removeItem(EXPIRE_TIME_KEY)
    }
  }

  useEffect(() => {
    if (isMounted) {
      return;
    }
    verifyInitialInactivity().then(() => {
      setIsMounted(true)
    }).catch(e => null);
  }, [])

  const updateExpireTime = () => {
    const expireTime = Date.now() + allowedInactivityTime;
    localStorage.setItem(EXPIRE_TIME_KEY, expireTime.toString());
  }

  useEffect(() => {
    if (!isMounted) {
      return
    }
    if (!user) {
      return
    }
    updateExpireTime()
    document.addEventListener("click", updateExpireTime);
    document.addEventListener("scroll", updateExpireTime);
    document.addEventListener("keypress", updateExpireTime);
    document.addEventListener("mousemove", updateExpireTime);

    return () => {
      document.removeEventListener("click", updateExpireTime);
      document.removeEventListener("scroll", updateExpireTime);
      document.removeEventListener("keypress", updateExpireTime);
      document.removeEventListener("mousemove", updateExpireTime);
      localStorage.removeItem(EXPIRE_TIME_KEY)
    }
  }, [user, isMounted]);

  useEffect(() => {
    if (!user) {
      return
    }
    if (!isMounted) {
      return
    }

    const checkInactivity = () => {
      const localExpireTime = localStorage.getItem(EXPIRE_TIME_KEY)

      if (!localExpireTime) {
        return;
      }

      if (Date.now() > parseInt(localExpireTime)) {
        logout();
        localStorage.removeItem(EXPIRE_TIME_KEY)
      }
    }

    const checkInterval = setInterval(() => checkInactivity(), 1000);

    return () => {
      clearInterval(checkInterval)
    };
  }, [user, logout, isMounted])

  return <>{children}</>
}