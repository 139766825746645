import { ReactNode } from "react";


export const StoneBox = ({ children }: { children: ReactNode }) => {
  return (
    <div
      className="w-full bg-stone-700 border border-stone-500 rounded-xl p-4">
      {children}
    </div>
  )
}
