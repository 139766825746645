import {StoneBox} from "../../components/Layouts/StoneBox";
import SubmitButton from "../../components/FormElements/SubmitButton";
import LabelledTextInput from "../../components/FormElements/LabelledTextInput";
import LabelledDropdown from "../../components/FormElements/LabelledDropdown";
import {Role} from "../../context/IAuthContext";
import {useMemo, useReducer, useState} from "react";
import {postNewUser} from "../../services/httpService";
import {ErrorMessage} from "../../components/FormElements/ErrorMessage";
import {NavigateBackButton} from "../../components/Buttons/NavigateBackButton";
import {useNavigate} from "react-router-dom";
import LabelledPasswordInput from "../../components/FormElements/LabelledPasswordInput";
import _ from "lodash";
import {useNotification} from "../../context/NotificationContext";

interface IUserReducerState {
  name: string,
  email: string,
  role: Role,
  password: string,
}

const shouldSubmitButtonBeDisabled = (initialState: IUserReducerState, currentState: IUserReducerState): boolean => {
  return _.isEqual(initialState, currentState)
    || currentState.name === ''
    || currentState.password === ''
    || currentState.email === ''
}

const reducer = (state: IUserReducerState, action: { type: string, value: string, key: string } | {
  type: 'reset'
}) => {
  switch (action.type) {
    case 'reset':
      return {...state, name: '', email: '', password: '', role: 'viewer' as Role}
    case 'onChange':
      return {...state, [action.key]: action.value}
    default:
      return state;
  }
}

export const CreateUser = () => {
  const initialCreateUserState: IUserReducerState = useMemo<IUserReducerState>(() => {
    return {
      name: '',
      email: '',
      role: 'viewer',
      password: '',
    }
  }, []);
  const [error, setError] = useState('');
  const [errorsList, setErrorsList] = useState<Record<string, string[]>>({});
  const [state, dispatch] = useReducer(reducer, initialCreateUserState);
  const navigate = useNavigate();
  const isSubmitButtonDisabled = shouldSubmitButtonBeDisabled(initialCreateUserState, state);
  const {showSuccess, showError} = useNotification();

  const handleInputChange = (value: string, name: keyof IUserReducerState) => {
    dispatch({type: 'onChange', key: name, value: value});
  }

  const resetForm = () => {
    dispatch({type: 'reset'});
  }

  const handleStoreAndCreateAnotherUser = async () => {
    setError('')
    setErrorsList({})
    try {
      await postNewUser({
        name: state.name,
        email: state.email,
        role: state.role,
        password: state.password
      })
      resetForm();
      showSuccess('Successfully added user!');
    } catch (e: any) {
      if (e.response?.status !== 422) {
        showError(e.response?.data?.message ?? "Something went wrong");
      }

      if (e.response?.data?.errors) {
        setErrorsList(e.response?.data?.errors);
      }
    }
  }

  const handleStoreUser = async () => {
    setErrorsList({})
    try {
      await postNewUser({
        name: state.name,
        email: state.email,
        role: state.role,
        password: state.password
      })
      showSuccess('Successfully added user!');
      navigate('/settings/users');
    } catch (e: any) {
      if (e.response?.status !== 422) {
        showError(e.response?.data?.message ?? "Something went wrong");
      }

      if (e.response?.data?.errors) {
        setErrorsList(e.response?.data?.errors);
      }
    }
  }

  return (
    <div className="flex justify-center">
      <div
        className="w-1/2 lg:w-1/5 text-white flex flex-col justify-start gap-4">
        <StoneBox>
          <NavigateBackButton onClick={() => navigate('/settings/users')} label={"Back to User Management"}/>
          <hr className="mb-4"/>
          <h1 className="text-3xl mb-4">
            Add a new user
          </h1>
          <div className="flex flex-col w-full space-between gap-4">
            <div className="flex-1">
              <LabelledTextInput
                label="Name"
                value={state.name}
                setValue={(value) => handleInputChange(value, 'name')}
                placeholder={'New User Name'}
                errors={errorsList.name}
              />
            </div>
            <div className="flex-1 text-md">
              <LabelledTextInput
                label="Email"
                value={state.email}
                setValue={(value) => handleInputChange(value, 'email')}
                placeholder={'New Email'}
                errors={errorsList.email}
              />
            </div>
            <div className="flex-1 text-md">
              <LabelledPasswordInput
                label="Password"
                value={state.password}
                setValue={(value) => handleInputChange(value, 'password')}
                placeholder={'New Password'}
                errors={errorsList.password}
              />
            </div>

            <div className="flex-1 text-md">
              <LabelledDropdown
                label="Role"
                value={state.role}
                options={[
                  {
                    name: 'Viewer',
                    value: 'viewer',
                  },
                  {
                    name: 'Manager',
                    value: 'manager',
                  },
                  {
                    name: 'Admin',
                    value: 'admin',
                  },
                ]}
                setValue={(value) => handleInputChange(value, 'role')}
              />
            </div>
          </div>
          <div className="pt-4 pb-4 flex flex-row gap-4 justify-center">
            <SubmitButton name="Create User" onClick={handleStoreUser} isDisabled={isSubmitButtonDisabled}/>
            <SubmitButton name="Create & Add Another" onClick={handleStoreAndCreateAnotherUser}
                          isDisabled={isSubmitButtonDisabled}/>
          </div>
          {error && <ErrorMessage message={error}/>}
        </StoneBox>
      </div>
    </div>
  )

}