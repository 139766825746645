import {StoneBox} from '../../components/Layouts/StoneBox';
import {NavigateBackButton} from '../../components/Buttons/NavigateBackButton';
import LabelledTextInput from '../../components/FormElements/LabelledTextInput';
import LabelledDropdown from '../../components/FormElements/LabelledDropdown';
import SubmitButton from '../../components/FormElements/SubmitButton';
import {useEffect, useMemo, useReducer, useState} from 'react';
import {IDevice} from '../DeviceManagement';
import {Role} from '../../context/IAuthContext';
import _ from 'lodash';
import {IHomeArea} from "../HomeArea/Interfaces";
import LabelledNumberDropdown from "../../components/FormElements/LabelledNumberDropdown";

interface IEditDeviceFormProps {
  navigateBack: () => void,
  onDeleteClick: () => Promise<void>,
  allHomeAreas: IHomeArea[],
  onSubmitClick: (deviceName: string, homeAreaId: string, role: string, accessLevel: number) => Promise<void>,
  deviceData: IDevice
}

interface IDeviceReducerState {
  name: string;
  accessLevel: number,
  homeAreaId: string;
  role: Role
}

const reducer = (state: IDeviceReducerState, action: { type: string, value: string | number, key: string }) => {
  switch (action.type) {
    case 'onChange':
      return {...state, [action.key]: action.value}
    default:
      return state;
  }
}

export function EditDeviceForm({
                                 navigateBack,
                                 onDeleteClick,
                                 allHomeAreas,
                                 onSubmitClick,
                                 deviceData
                               }: IEditDeviceFormProps) {
  const initialState = useMemo<IDeviceReducerState>(() => {
    return {
      name: deviceData.name,
      accessLevel: deviceData.accessLevel,
      homeAreaId: deviceData.homeArea.id,
      role: deviceData.role,
    } as IDeviceReducerState
  }, [deviceData])

  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(true);
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleInputChange = (value: string | number, name: keyof IDeviceReducerState) => {
    dispatch({type: 'onChange', key: name, value: value});
  }
  const handleSubmit = () => {
    onSubmitClick(state.name, state.homeAreaId, state.role, state.accessLevel);
  }

  useEffect(() => {
    if (!state.name) {
      return setIsUpdateButtonDisabled(true);
    }
    if (
      !_.isEqual(initialState, state)
    ) {
      setIsUpdateButtonDisabled(false)
    } else {
      setIsUpdateButtonDisabled(true)
    }

  }, [initialState, state])

  return <StoneBox>
    <div className="flex flex-row align-middle justify-between">
      <NavigateBackButton onClick={navigateBack} label={'Back to Devices Management'}/>
      <button
        className="mb-2 px-4 py-2 rounded text-red-400 select-none hover:bg-red-400 hover:text-red-800"
        onClick={onDeleteClick}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
             stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
        </svg>
        <span className="sr-only">Delete Device</span>
      </button>
    </div>

    <hr className="mb-4"/>
    <h1 className="text-3xl">
      Edit Device
    </h1>
    <div>
      <div className="pt-4 flex flex-row w-full space-between gap-4">
        <div className="flex-1">
          <LabelledTextInput label="Device Name" value={state.name}
                             setValue={(value) => handleInputChange(value, 'name')}/>
        </div>
        <div className="flex-1">
          <LabelledDropdown
            label="Home Area"
            value={state.homeAreaId}
            options={
              allHomeAreas.map(a => {
                return {
                  name: a.name,
                  value: a.id
                }
              })
            }
            setValue={(value: string) => handleInputChange(value, 'homeAreaId')}
          />
        </div>
        <div className="flex-1">
          <LabelledNumberDropdown
            label="Access Level"
            value={state.accessLevel}
            setValue={(value) => handleInputChange(value, 'accessLevel')}
            options={[
              {
                name: '1',
                value: 1,
              },
              {
                name: '2',
                value: 2,
              },
              {
                name: '3',
                value: 3,
              }
            ]}
          />
        </div>
        <div className="flex-1">
          <LabelledDropdown
            label="Role"
            value={state.role}
            options={[
              {
                name: 'Viewer',
                value: 'viewer',
              },
              {
                name: 'Manager',
                value: 'manager',
              },
              {
                name: 'Admin',
                value: 'admin',
              },
            ]}
            setValue={(value: string) => handleInputChange(value, 'role')}
          />
        </div>
      </div>
      <div className="pt-4 flex flex-row w-full space-between gap-4 pb-4">
        <SubmitButton
          name={'Update Device'}
          onClick={handleSubmit}
          isDisabled={isUpdateButtonDisabled}
        />
      </div>
    </div>
  </StoneBox>;
}
