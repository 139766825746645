import LabelledCheckbox from "../FormElements/LabelledCheckbox";
import SubmitButton from "../FormElements/SubmitButton";
import {useEffect, useState} from "react";
import {IRoomSettings} from "../../pages/RoomSettings";

interface IRoomSettingsForm {
  roomSettings: IRoomSettings,
  handleFormSubmit: (settings: IRoomSettings) => void,
  name: string,
}

export const RoomSettingsForm = ({
                                   roomSettings,
                                   handleFormSubmit,
                                   name,
                                 }: IRoomSettingsForm) => {
  const [highRiskForFalls, setHighRiskForFalls] = useState(roomSettings.highRiskForFalls)
  const [requiresMultiplePersonAssistanceForBedExitAndToileting, setRequiresMultiplePersonAssistanceForBedExitAndToileting] = useState(roomSettings.requiresMultiplePersonAssistanceForBedExitAndToileting)
  const [alertAttemptedBedExit, setAlertAttemptedBedExit] = useState(roomSettings.alertAttemptedBedExit)

  const [alertOutOfBedMorning, setAlertOutOfBedMorning] = useState(roomSettings.alertOutOfBedMorning)
  const [alertOutOfBedAfternoon, setAlertOutOfBedAfternoon] = useState(roomSettings.alertOutOfBedAfternoon)
  const [alertOutOfBedNight, setAlertOutOfBedNight] = useState(roomSettings.alertOutOfBedNight)

  const [alertOutOfRoomMorning, setAlertOutOfRoomMorning] = useState(roomSettings.alertOutOfRoomMorning)
  const [alertOutOfRoomAfternoon, setAlertOutOfRoomAfternoon] = useState(roomSettings.alertOutOfRoomAfternoon)
  const [alertOutOfRoomNight, setAlertOutOfRoomNight] = useState(roomSettings.alertOutOfRoomNight)

  const [alertNoMovementDetectedMorning, setAlertNoMovementDetectedMorning] = useState(roomSettings.alertNoMovementDetectedMorning)
  const [alertNoMovementDetectedAfternoon, setAlertNoMovementDetectedAfternoon] = useState(roomSettings.alertNoMovementDetectedAfternoon)
  const [alertNoMovementDetectedNight, setAlertNoMovementDetectedNight] = useState(roomSettings.alertNoMovementDetectedNight)


  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  useEffect(() => {
    setHighRiskForFalls(roomSettings.highRiskForFalls);
    setRequiresMultiplePersonAssistanceForBedExitAndToileting(roomSettings.requiresMultiplePersonAssistanceForBedExitAndToileting);
    setAlertAttemptedBedExit(roomSettings.alertAttemptedBedExit);
    setAlertOutOfBedMorning(roomSettings.alertOutOfBedMorning);
    setAlertOutOfBedAfternoon(roomSettings.alertOutOfBedAfternoon);
    setAlertOutOfBedNight(roomSettings.alertOutOfBedNight);
    setAlertOutOfRoomMorning(roomSettings.alertOutOfRoomMorning);
    setAlertOutOfRoomAfternoon(roomSettings.alertOutOfRoomAfternoon);
    setAlertOutOfRoomNight(roomSettings.alertOutOfRoomNight);
    setAlertNoMovementDetectedMorning(roomSettings.alertNoMovementDetectedMorning);
    setAlertNoMovementDetectedAfternoon(roomSettings.alertNoMovementDetectedAfternoon);
    setAlertNoMovementDetectedNight(roomSettings.alertNoMovementDetectedNight);
  }, [roomSettings]);

  useEffect(() => {
    if (
      highRiskForFalls !== roomSettings.highRiskForFalls
      || requiresMultiplePersonAssistanceForBedExitAndToileting !== roomSettings.requiresMultiplePersonAssistanceForBedExitAndToileting
      || alertAttemptedBedExit !== roomSettings.alertAttemptedBedExit
      || alertOutOfBedMorning !== roomSettings.alertOutOfBedMorning
      || alertOutOfBedAfternoon !== roomSettings.alertOutOfBedAfternoon
      || alertOutOfBedNight !== roomSettings.alertOutOfBedNight
      || alertOutOfRoomMorning !== roomSettings.alertOutOfRoomMorning
      || alertOutOfRoomAfternoon !== roomSettings.alertOutOfRoomAfternoon
      || alertOutOfRoomNight !== roomSettings.alertOutOfRoomNight
      || alertNoMovementDetectedMorning !== roomSettings.alertNoMovementDetectedMorning
      || alertNoMovementDetectedAfternoon !== roomSettings.alertNoMovementDetectedAfternoon
      || alertNoMovementDetectedNight !== roomSettings.alertNoMovementDetectedNight
    ) {
      setIsSaveButtonDisabled(false);
    } else {
      setIsSaveButtonDisabled(true);
    }

  }, [roomSettings, highRiskForFalls, requiresMultiplePersonAssistanceForBedExitAndToileting, alertAttemptedBedExit, alertOutOfBedMorning, alertOutOfBedAfternoon, alertOutOfBedNight, alertOutOfRoomMorning, alertOutOfRoomAfternoon, alertOutOfRoomNight, alertNoMovementDetectedMorning, alertNoMovementDetectedAfternoon, alertNoMovementDetectedNight]);
  const handleSubmit = () => {
    handleFormSubmit(
      {
        highRiskForFalls,
        requiresMultiplePersonAssistanceForBedExitAndToileting,
        alertOutOfBedMorning,
        alertOutOfBedAfternoon,
        alertOutOfBedNight,
        alertOutOfRoomMorning,
        alertOutOfRoomAfternoon,
        alertOutOfRoomNight,
        alertAttemptedBedExit,
        alertNoMovementDetectedMorning,
        alertNoMovementDetectedAfternoon,
        alertNoMovementDetectedNight,
      }
    )
  }

  return (
    <div className="flex-1 flex flex-col justify-start bg-stone-700 rounded-lg p-4 border border-stone-500">
      <h1 className="text-3xl">Settings</h1>
      <div className="text-2xl mt-3 flex flex-col gap-4">
        <div className="flex flex-col">
          <div className="hidden">
            <LabelledCheckbox
              label="High risk for falls"
              isChecked={highRiskForFalls}
              setIsChecked={setHighRiskForFalls}
            />

            <LabelledCheckbox
              label="Requires multi-person aid for bed & toilet"
              isChecked={requiresMultiplePersonAssistanceForBedExitAndToileting}
              setIsChecked={setRequiresMultiplePersonAssistanceForBedExitAndToileting}
            />
          </div>

          <LabelledCheckbox
            label="Non-ambulatory bed exit alert"
            isChecked={alertAttemptedBedExit}
            setIsChecked={setAlertAttemptedBedExit}
          />

        <div className="mt-3">
          Alert out of bed
          <div className="pl-7 flex flex-col">
            <LabelledCheckbox
              label="Morning"
              isChecked={alertOutOfBedMorning}
              setIsChecked={setAlertOutOfBedMorning}
            />
            <LabelledCheckbox
              label="Afternoon"
              isChecked={alertOutOfBedAfternoon}
              setIsChecked={setAlertOutOfBedAfternoon}
            />
            <LabelledCheckbox
              label="Night"
              isChecked={alertOutOfBedNight}
              setIsChecked={setAlertOutOfBedNight}
            />
          </div>
        </div>

        <div className="mt-3">
          Alert out of room
          <div className="pl-7 flex flex-col">
            <LabelledCheckbox
              label="Morning"
              isChecked={alertOutOfRoomMorning}
              setIsChecked={setAlertOutOfRoomMorning}
            />
            <LabelledCheckbox
              label="Afternoon"
              isChecked={alertOutOfRoomAfternoon}
              setIsChecked={setAlertOutOfRoomAfternoon}
            />
            <LabelledCheckbox
              label="Night"
              isChecked={alertOutOfRoomNight}
              setIsChecked={setAlertOutOfRoomNight}
            />
          </div>
        </div>
          <div className="mt-3">
            Alert no movement detected
            <div className="pl-7 flex flex-col">
              <LabelledCheckbox
                label="Morning"
                isChecked={alertNoMovementDetectedMorning}
                setIsChecked={setAlertNoMovementDetectedMorning}
              />
              <LabelledCheckbox
                label="Afternoon"
                isChecked={alertNoMovementDetectedAfternoon}
                setIsChecked={setAlertNoMovementDetectedAfternoon}
              />
              <LabelledCheckbox
                label="Night"
                isChecked={alertNoMovementDetectedNight}
                setIsChecked={setAlertNoMovementDetectedNight}
              />
            </div>
          </div>
        </div>
        <SubmitButton name="Save Room Settings" isDisabled={isSaveButtonDisabled} onClick={handleSubmit}/>
      </div>
    </div>
  );
}
