import {useEffect, useState} from 'react';
import {deleteDevice, getDevice, getHomeAreas, updateDevice} from '../../services/httpService';
import {useNavigate, useParams} from 'react-router-dom';
import {EditDeviceForm} from './EditDeviceForm';
import {IDevice} from '../DeviceManagement';
import {StoneBox} from '../../components/Layouts/StoneBox';
import {Role} from '../../context/IAuthContext';
import {IHomeArea} from "../HomeArea/Interfaces";
import {useNotification} from "../../context/NotificationContext";

export const EditDevice = () => {
  const navigate = useNavigate();
  const {deviceId} = useParams() as { deviceId: string }
  const [deviceIdentifier, setDeviceIdentifier] = useState('')
  const [homeAreas, setHomeAreas] = useState<IHomeArea[]>([])
  const [isLoading, setIsLoading] = useState(true);
  const [deviceData, setDeviceData] = useState<IDevice | null>(null);
  const {showSuccess, showError} = useNotification();

  useEffect(() => {
    (async () => {
      try {
        const fetchedHomeAreas = await getHomeAreas()
        setHomeAreas(fetchedHomeAreas)

        const deviceDataResponse = await getDevice(deviceId)
        setDeviceData(deviceDataResponse);
        setDeviceIdentifier(deviceDataResponse?.deviceId || '')
        setIsLoading(false)
      } catch (e) {
        console.error(e)
      }

    })()
  }, [])

  const handleUpdateDevice = async (deviceName: string, homeAreaId: string, role: string, accessLevel: number) => {
    try {
      const res = await updateDevice(deviceId, {name: deviceName, homeAreaId, role, accessLevel})
      showSuccess(res.message);
      setDeviceData((previousDevice) => {
        if (!previousDevice) {
          return null;
        }
        return {
          ...previousDevice,
          name: deviceName,
          accessLevel: accessLevel,
          homeArea: {
            id: homeAreaId,
            name: homeAreas.find((homeArea) => homeArea.id === homeAreaId)?.name || ''
          },
          role: role as Role
        }
      })

    } catch (e: any) {
      if (e.response?.status === 400) {
        showError(e.response.data.message);
      }
    }
  }

  const handleDelete = async () => {
    if (window.confirm('Warning: Do you really want to delete this device?')) {
      try {
        await deleteDevice(deviceId);
        showSuccess('Successfully deleted device.');
        navigate("/settings/devices");
      } catch (e: any) {
      }
    }

  }

  if (isLoading || !deviceData) {
    return <div className="flex justify-center mt-16">
      <div
        className="w-1/2 text-white flex flex-col justify-start">
        <StoneBox>Loading...</StoneBox>
      </div>
    </div>
  }

  return (
    <div className="flex justify-center mt-16">
      <div
        className="w-1/2 text-white flex flex-col justify-start">
        <EditDeviceForm navigateBack={() => navigate('/settings/devices')}
                        onDeleteClick={handleDelete}
                        allHomeAreas={homeAreas}
                        onSubmitClick={handleUpdateDevice}
                        deviceData={deviceData}
        />
      </div>
    </div>
  )
}
