import {ProtectedView} from '../Layouts/ProtectedView';

interface IMenuProps {
  username: string,
  email: string,
  handleLogout: () => void,
  handleSettingsClick: () => void,
  handleEditProfileClick: () => void,
}

export const UserDropdownMenu = (props: IMenuProps) => {
  const {
    username,
    email,
    handleLogout,
    handleSettingsClick,
    handleEditProfileClick
  } = props;

  return (
    <div
      className='z-40 hover:cursor-auto absolute right-0 flex flex-col bg-stone-600 border-2 border-sky-500 rounded-lg text-xl text-white p-2.5 mt-5'>
      <div className='font-bold'>{username}</div>
      <div className='opacity-50'>{email}</div>
      <hr className='my-2'/>
      <button className="text-left" onClick={handleEditProfileClick}>Edit Profile</button>
      <ProtectedView roles={['manager', 'admin']}>
        <button className="text-left" onClick={handleSettingsClick}>Settings</button>
      </ProtectedView>
      <button className="text-left" onClick={handleLogout}>Logout</button>
    </div>
  );
}
