import { ReactNode } from "react";
import { useAuth } from "../../context/AuthContext";
import { Navigate, useLocation } from "react-router-dom";
import { Role } from '../../context/IAuthContext';
import { MessagePage } from '../../components/Layouts/MessagePage';

export const ProtectedRoute = ({children, roles}: { children: ReactNode, roles: Array<Role> }): JSX.Element => {
  const location = useLocation();
  const { user } = useAuth();

  if (!user) {
    return <Navigate to={`/login?redirect=${location.pathname}`} replace/>;
  }

  if (!roles.includes(user.role)) {
    return <MessagePage message="Unauthorized Access."/>
  }

  return <>{children}</>
}
