import {HomeAreaView} from './HomeAreaView/HomeAreaView';
import {useNavigate} from 'react-router-dom';
import {MessagePage} from "../../components/Layouts/MessagePage";
import {useHomeAreaPage} from "./HomeAreaPage.hooks";

const HomeAreaPage = () => {
  const {rooms, loading, homeAreas, currentHomeArea} = useHomeAreaPage();
  const navigate = useNavigate();


  const handleHomeAreaChange = (homeAreaId: string) => {
    navigate(`/home-areas/${homeAreaId}`)
  }

  if (loading) {
    return <MessagePage message='Loading...'/>
  }

  if (!currentHomeArea) {
    return <MessagePage message='404: Home Area is not found'/>
  }

  return (
    <HomeAreaView
      homeArea={currentHomeArea}
      rooms={rooms}
      homeAreas={homeAreas}
      onHomeAreaChange={(homeAreaId: string) => handleHomeAreaChange(homeAreaId)}
    />
  );
}

export default HomeAreaPage;
