import {Login} from './LoginView/Login';
import {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {getHomeAreas} from '../../services/httpService';
import {useAuth} from "../../context/AuthContext";
import {useNotification} from "../../context/NotificationContext";

const LoginPage = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
  const [searchParams] = useSearchParams()
  const redirect = searchParams.get('redirect');
  const {showError} = useNotification();
  const [errorsList, setErrorsList] = useState<Record<string, string[]>>({});

  useEffect(() => {
    (async () => {
      const token: string | null = localStorage.getItem('bearer_token');
      const role: string | null = localStorage.getItem('role');
      if (token && role) {
        const homeAreas = await getHomeAreas();
        const firstHomeArea = homeAreas[0].id
        navigate(`/home-areas/${firstHomeArea}`);
      }
    })()
  }, [])

  const handleSubmit = async (username: string, password: string) => {
    setErrorsList({});
    try {
      await login(username, password);
      if (redirect) {
        navigate(redirect);
      } else {
        const homeAreas = await getHomeAreas();
        const firstHomeArea = homeAreas[0].id
        navigate(`/home-areas/${firstHomeArea}`)
      }
    } catch (e: any) {
      if (e.response?.data?.errors) {
        setErrorsList(e.response?.data?.errors);
      }
      if (e.response?.status !== 422) {
        showError(e.response?.data?.message ?? 'The provided credentials are incorrect. Please contact an admin if you require assistance.')
      }
    }
  }

  return (
    <div className="h-full">
      <Login onSubmit={handleSubmit} errorsList={errorsList}/>
    </div>
  )
}

export default LoginPage
