import {IRoomSettings} from '../index';
import {NavigateBackButton} from "../../../components/Buttons/NavigateBackButton";
import {IResidentProfile} from "./Interfaces";
import {ResidentProfileForm} from "../../../components/Forms/ResidentProfileForm";
import {RoomSettingsForm} from "../../../components/Forms/RoomSettingsForm";
import {TwoColumns} from "../../../components/Layouts/TwoColumns";
import {StoneBox} from "../../../components/Layouts/StoneBox";
import {IDetailedRoom} from "../../HomeArea/Interfaces";
import SecondaryButton from "../../../components/FormElements/SecondaryButton";
import {storeManualBedStatus} from "../../../services/httpService";
import {useNotification} from "../../../context/NotificationContext";
import {ProtectedView} from "../../../components/Layouts/ProtectedView";

interface IRoomSettingsViewProps {
  onBackBtnClick: () => void,
  room: IDetailedRoom,
  roomSettings: IRoomSettings,
  onSubmit: (settings: IRoomSettings) => void,
  residentProfile: IResidentProfile,
  onPersonalInfoSubmit: (residentProfile: IResidentProfile) => void
  vacateRoomBtnClick: () => void
  handleTransferBtnClick: () => void
  handleManageAbsenceBtnClick: () => void
}

export function RoomSettingsView({
                                   onBackBtnClick,
                                   room,
                                   roomSettings,
                                   onSubmit,
                                   residentProfile,
                                   onPersonalInfoSubmit,
                                   vacateRoomBtnClick,
                                   handleTransferBtnClick,
                                   handleManageAbsenceBtnClick
                                 }: IRoomSettingsViewProps) {

  const {showSuccess} = useNotification();

  const handleFormSubmit = (settings: IRoomSettings) => {
    onSubmit(settings);
  }

  async function handleStoreStatusClick(status: string): Promise<void> {
    try {
      const res = await storeManualBedStatus(room.id, status);
      showSuccess(res.message)
    } catch (e) {
    }
  }

  const sideContent =
    <div className="flex flex-col gap-6">
      <StoneBox>
        <div className="flex flex-col justify-start text-white text-xl">
          <NavigateBackButton onClick={onBackBtnClick} label="Back to Room View"/>
          <hr className='mb-4'/>
          <div>Room: {room?.display_name}</div>
          <div>Home Area: {room?.homeArea.name}</div>
        </div>
      </StoneBox>

      <ProtectedView roles={['admin']}>
        <StoneBox>
          <div className="flex flex-col justify-start text-white text-xl gap-3">
            <h1 className="text-2xl">Trigger Test</h1>
            <SecondaryButton onClick={() => handleStoreStatusClick('bed-exit')} name={'Bed Exit'}/>
            <SecondaryButton onClick={() => handleStoreStatusClick('out-of-bed')} name={'Out Of Bed'}/>
            <SecondaryButton onClick={() => handleStoreStatusClick('out-of-room')} name={'Out Of Room'}/>
            <SecondaryButton onClick={() => handleStoreStatusClick('no-movement')} name={'No Movement'}/>
            <SecondaryButton onClick={() => handleStoreStatusClick('fall')} name={'Fall'}/>
            <SecondaryButton onClick={() => handleStoreStatusClick('room-entry')} name={'Room Entry'}/>
          </div>
        </StoneBox>
      </ProtectedView>
      {/*{*/}
      {/*  room.status !== 'vacant' && <StoneBox>*/}
      {/*    <div>*/}
      {/*      <h2 className="text-2xl mb-2">Occupancy</h2>*/}
      {/*      <div className="flex flex-col justify-center gap-2">*/}
      {/*        <SecondaryButton name='Manage Absence' onClick={handleManageAbsenceBtnClick}/>*/}
      {/*        <SecondaryButton name='Transfer Resident' onClick={handleTransferBtnClick}/>*/}
      {/*        <ResetButton name='Vacate Room' onClick={vacateRoomBtnClick}/>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </StoneBox>*/}
      {/*}*/}
    </div>

  const mainContent = <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
    <RoomSettingsForm
      name={room?.name}
      roomSettings={roomSettings}
      handleFormSubmit={handleFormSubmit}
    />
    <ResidentProfileForm roomName={room?.name}
                         serverState={residentProfile}
                         onClick={onPersonalInfoSubmit}
    />
  </div>

  return <TwoColumns side={sideContent} main={mainContent}/>
}

