import {IExceptionThresholdGroup, IFacilitySettings} from "./Interfaces";
import {useEffect, useReducer, useState} from "react";
import SubmitButton from "../../components/FormElements/SubmitButton";
import LabelledTimeInput from '../../components/FormElements/LabelledTimeInput';
import LabelledNumberDropdown from "../../components/FormElements/LabelledNumberDropdown";
import {InfoMessage} from '../../components/FormElements/InfoMessage';
import _ from 'lodash';
import {ChevronDown} from "../../components/Icons/ChevronDown";
import {ChevronUp} from "../../components/Icons/ChevronUp";

const reducer = (state: IFacilitySettings, action: { type: string, value: number | string, key: string }) => {
  switch (action.type) {
    case 'setText':
      return { ...state, [action.key]: action.value }
    case 'set':
      return { ...state, [action.key]: action.value }
    default:
      throw new Error();
  }
}

interface FacilitySettingsProps {
  initialState: IFacilitySettings;
  submit: (settings: IFacilitySettings) => void;
  exceptionThresholdOptions: IExceptionThresholdGroup,
}

export const FacilitySettingsForm = ({
                                       initialState,
                                       submit,
                                       exceptionThresholdOptions,
                                     }: FacilitySettingsProps,
) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [isMorningSectionExpanded, setIsMorningSectionExpanded] = useState(false);
  const [isAfternoonSectionExpanded, setIsAfternoonSectionExpanded] = useState(false);
  const [isEveningSectionExpanded, setIsEveningSectionExpanded] = useState(false);

  const setSectionExpanded = (setter: React.Dispatch<React.SetStateAction<boolean>>) => {
    setter((prev) => !prev);
  }

  useEffect(() => {
    if (!_.isEqual(initialState, state)) {
      setIsSaveButtonDisabled(false)
    } else {
      setIsSaveButtonDisabled(true)
    }

  }, [initialState, state]);

  const handleInputChange = (value: number, name: keyof IFacilitySettings) => {
    dispatch({ type: 'set', key: name, value: value });
  }

  const handleTextInputChange = (value: string, name: keyof IFacilitySettings) => {
    dispatch({type: 'setText', key: name, value: value});
  }

  const handleSubmit = () => {
    submit(state);
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col border-2 border-stone-500 rounded-xl mt-6">
        <div
          className="p-6 flex flex-row gap-4 justify-between cursor-pointer rounded-xl items-center hover:bg-stone-600 transition duration-150 ease-in-out"
             onClick={() => setSectionExpanded(setIsMorningSectionExpanded)}>
          <p className="text-2xl">Morning Exception Reporting Settings</p>
          {isMorningSectionExpanded ? <ChevronUp/> : <ChevronDown/>}
        </div>
        <div data-testid='morning'
             className={isMorningSectionExpanded ? "grid grid-cols-2 lg:grid-cols-3 w-full space-between gap-4 pt-0 p-6" : "hidden"}>
          <LabelledNumberDropdown
            label={"Change in Toileting Frequency"}
            value={state.morningToiletingFrequency}
            setValue={(value: number) => handleInputChange(value, 'morningToiletingFrequency')}
            options={exceptionThresholdOptions.toiletingFrequency}
          />
          <LabelledNumberDropdown
            label={"Change in Toileting Duration"}
            value={state.morningToiletingDuration}
            setValue={(value: number) => handleInputChange(value, 'morningToiletingDuration')}
            options={exceptionThresholdOptions.toiletingDuration}
          />
          <LabelledNumberDropdown
            label={"Change in Active Time"}
            value={state.morningActiveTime}
            setValue={(value: number) => handleInputChange(value, 'morningActiveTime')}
            options={exceptionThresholdOptions.activeTime}
          />
          <LabelledNumberDropdown
            label={"Change in Sedentary Time"}
            value={state.morningSedentaryTime}
            setValue={(value: number) => handleInputChange(value, 'morningSedentaryTime')}
            options={exceptionThresholdOptions.sedentaryTime}
          />
          <LabelledNumberDropdown
            label={"Change in Bed Exits"}
            value={state.morningOutOfBed}
            setValue={(value: number) => handleInputChange(value, 'morningOutOfBed')}
            options={exceptionThresholdOptions.outOfBed}
          />
          <LabelledNumberDropdown
            label={"No Movement Detected"}
            value={state.morningNoMovementDetected}
            setValue={(value: number) => handleInputChange(value, 'morningNoMovementDetected')}
            options={exceptionThresholdOptions.noMovementDetected}
          />
          <LabelledNumberDropdown
            label={"Change in Bed Exit Routine Duration"}
            value={state.morningBedExitRoutine}
            setValue={(value: number) => handleInputChange(value, 'morningBedExitRoutine')}
            options={exceptionThresholdOptions.bedExitRoutine}
            disabled={true}
          />
          <LabelledNumberDropdown

            label={"Change in Gait Speed"}
            value={state.morningGaitSpeed}
            setValue={(value: number) => handleInputChange(value, 'morningGaitSpeed')}
            options={exceptionThresholdOptions.gaitSpeed}
            disabled={true}
          />
        </div>
      </div>
      <div className="flex flex-col border-2 border-stone-500 rounded-xl">
        <div
          className="p-6 flex flex-row gap-4 justify-between cursor-pointer rounded-xl items-center hover:bg-stone-600 transition duration-150 ease-in-out"
             onClick={() => setSectionExpanded(setIsAfternoonSectionExpanded)}>
          <p className="text-2xl">Afternoon Exception Reporting Settings</p>
          {isAfternoonSectionExpanded ? <ChevronUp/> : <ChevronDown/>}
        </div>
        <div data-testid='afternoon'
             className={isAfternoonSectionExpanded ? "grid grid-cols-2 lg:grid-cols-3 w-full space-between gap-4 pt-0 p-6" : "hidden"}>
          <LabelledNumberDropdown
            label={"Change in Toileting Frequency"}
            value={state.afternoonToiletingFrequency}
            setValue={(value: number) => handleInputChange(value, 'afternoonToiletingFrequency')}
            options={exceptionThresholdOptions.toiletingFrequency}
          />
          <LabelledNumberDropdown
            label={"Change in Toileting Duration"}
            value={state.afternoonToiletingDuration}
            setValue={(value: number) => handleInputChange(value, 'afternoonToiletingDuration')}
            options={exceptionThresholdOptions.toiletingDuration}
          />
          <LabelledNumberDropdown
            label={"Change in Active Time"}
            value={state.afternoonActiveTime}
            setValue={(value: number) => handleInputChange(value, 'afternoonActiveTime')}
            options={exceptionThresholdOptions.activeTime}
          />
          <LabelledNumberDropdown
            label={"Change in Sedentary Time"}
            value={state.afternoonSedentaryTime}
            setValue={(value: number) => handleInputChange(value, 'afternoonSedentaryTime')}
            options={exceptionThresholdOptions.sedentaryTime}
          />
          <LabelledNumberDropdown
            label={"Change in Bed Exits"}
            value={state.afternoonOutOfBed}
            setValue={(value: number) => handleInputChange(value, 'afternoonOutOfBed')}
            options={exceptionThresholdOptions.outOfBed}
          />
          <LabelledNumberDropdown
            label={"No Movement Detected"}
            value={state.afternoonNoMovementDetected}
            setValue={(value: number) => handleInputChange(value, 'afternoonNoMovementDetected')}
            options={exceptionThresholdOptions.noMovementDetected}
          />
          <LabelledNumberDropdown
            label={"Change in Bed Exit Routine Duration"}
            value={state.afternoonBedExitRoutine}
            setValue={(value: number) => handleInputChange(value, 'afternoonBedExitRoutine')}
            options={exceptionThresholdOptions.bedExitRoutine}
            disabled={true}
          />
          <LabelledNumberDropdown

            label={"Change in Gait Speed"}
            value={state.afternoonGaitSpeed}
            setValue={(value: number) => handleInputChange(value, 'afternoonGaitSpeed')}
            options={exceptionThresholdOptions.gaitSpeed}
            disabled={true}
          />
        </div>
      </div>
      <div className="flex flex-col border-2 border-stone-500 rounded-xl">
        <div
          className="p-6 flex flex-row gap-4 justify-between cursor-pointer rounded-xl items-center hover:bg-stone-600 transition duration-150 ease-in-out"
             onClick={() => setSectionExpanded(setIsEveningSectionExpanded)}>
          <p className="text-2xl">Evening Exception Reporting Settings</p>
          {isEveningSectionExpanded ? <ChevronUp/> : <ChevronDown/>}
        </div>
        <div data-testid='evening'
             className={isEveningSectionExpanded ? "grid grid-cols-2 lg:grid-cols-3 w-full space-between gap-4 pt-0 p-6" : "hidden"}>
          <LabelledNumberDropdown
            label={"Change in Toileting Frequency"}
            value={state.eveningToiletingFrequency}
            setValue={(value: number) => handleInputChange(value, 'eveningToiletingFrequency')}
            options={exceptionThresholdOptions.toiletingFrequency}
          />
          <LabelledNumberDropdown
            label={"Change in Toileting Duration"}
            value={state.eveningToiletingDuration}
            setValue={(value: number) => handleInputChange(value, 'eveningToiletingDuration')}
            options={exceptionThresholdOptions.toiletingDuration}
          />
          <LabelledNumberDropdown
            label={"Change in Active Time"}
            value={state.eveningActiveTime}
            setValue={(value: number) => handleInputChange(value, 'eveningActiveTime')}
            options={exceptionThresholdOptions.activeTime}
          />
          <LabelledNumberDropdown
            label={"Change in Sedentary Time"}
            value={state.eveningSedentaryTime}
            setValue={(value: number) => handleInputChange(value, 'eveningSedentaryTime')}
            options={exceptionThresholdOptions.sedentaryTime}
          />
          <LabelledNumberDropdown
            label={"Change in Bed Exits"}
            value={state.eveningOutOfBed}
            setValue={(value: number) => handleInputChange(value, 'eveningOutOfBed')}
            options={exceptionThresholdOptions.outOfBed}
          />
          <LabelledNumberDropdown
            label={"No Movement Detected"}
            value={state.eveningNoMovementDetected}
            setValue={(value: number) => handleInputChange(value, 'eveningNoMovementDetected')}
            options={exceptionThresholdOptions.noMovementDetected}
          />
          <LabelledNumberDropdown
            label={"Change in Bed Exit Routine Duration"}
            value={state.eveningBedExitRoutine}
            setValue={(value: number) => handleInputChange(value, 'eveningBedExitRoutine')}
            options={exceptionThresholdOptions.bedExitRoutine}
            disabled={true}
          />
          <LabelledNumberDropdown

            label={"Change in Gait Speed"}
            value={state.eveningGaitSpeed}
            setValue={(value: number) => handleInputChange(value, 'eveningGaitSpeed')}
            options={exceptionThresholdOptions.gaitSpeed}
            disabled={true}
          />
        </div>
      </div>
      <InfoMessage
        message="Change in activity will be flagged if a resident deviates from their historical average, based on these thresholds."/>
      <p className="text-2xl pt-4">Shift Configuration</p>
      <div className="flex flex-row w-full space-between gap-4">
        <LabelledTimeInput
          label="Morning Shift Start Time"
          value={state.morningShiftStart}
          setValue={(value: string) => handleTextInputChange(value, 'morningShiftStart')}
        />

        <LabelledTimeInput
          label="Afternoon Shift Start Time"
          value={state.afternoonShiftStart}
          setValue={(value: string) => handleTextInputChange(value, 'afternoonShiftStart')}
        />

        <LabelledTimeInput
          label="Evening Shift Start Time"
          value={state.eveningShiftStart}
          setValue={(value: string) => handleTextInputChange(value, 'eveningShiftStart')}
        />
      </div>
      <div className="flex flex-row w-full space-between gap-4 pt-2">
        <SubmitButton name="Save" onClick={handleSubmit} isDisabled={isSaveButtonDisabled}/>
      </div>

    </div>
  )
}
