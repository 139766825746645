import React from 'react';

type LabelledPasswordInputProps = {
  label: string,
  value: string,
  setValue: (value: string) => void,
  placeholder?: string,
  errors?: string[],
  hideHint?: boolean
}


function LabelledPasswordInput({label, value, setValue, placeholder, errors, hideHint}: LabelledPasswordInputProps) {
  return (
    <>
      <label className="block w-full text-md font-medium leading-6 text-white">
        {label}
        <input
          type="password"
          className="block w-full rounded-md border-0 py-2 px-2 text-stone-900 shadow-sm ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
        />
      </label>
      {
        !hideHint && <p className="text-xs font-medium leading-snug pt-2 text-stone-200">
              Must include a number, symbol and capital. 8+ characters.
          </p>
      }

      {errors && errors.length > 0 &&
          <ul className={"text-red-500 list-disc py-1 px-4 text-xs font-medium"}>
            {errors?.map((e, index) => <li key={index}>{e}</li>)}
          </ul>}
    </>
  );
}

export default LabelledPasswordInput;
