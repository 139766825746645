import {StoneBox} from '../../components/Layouts/StoneBox';
import LabelledPasswordInput from "../../components/FormElements/LabelledPasswordInput";
import SubmitButton from "../../components/FormElements/SubmitButton";
import {useState} from "react";
import {updateUserProfile} from "../../services/httpService";
import {useNotification} from "../../context/NotificationContext";
import {NavigateBackButton} from "../../components/Buttons/NavigateBackButton";
import {useNavigate} from "react-router-dom";

export const EditProfile = () => {

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [errorsList, setErrorsList] = useState<Record<string, string[]>>({});
  const navigate = useNavigate();
  const {showSuccess, showError} = useNotification();


  const passwordDontMatch = confirmNewPassword !== newPassword && confirmNewPassword !== ""
  const formHasEmptyFields = currentPassword === ''
    || newPassword === ''
    || confirmNewPassword === ''
    || newPassword !== confirmNewPassword
  ;
  async function handleSubmit() {
    setErrorsList({})
    try {
      await updateUserProfile({
        currentPassword,
        newPassword,
        confirmNewPassword,
      });
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');

      showSuccess('Successfully edited password!');

    } catch (e: any) {
      if (e.response?.status !== 422) {
        showError(e.response?.data?.message ?? "Something went wrong");
      }

      if (e.response?.data?.errors) {
        setErrorsList(e.response?.data?.errors)
      }
    }

  }

  return (
    <div className="flex justify-center">
      <div
        className="w-3/4 md:w-1/2 lg:w-1/3 xl:w-1/5 text-white flex flex-col justify-start">
        <StoneBox>
          <NavigateBackButton onClick={() => navigate(-1)} label={"Back"}/>
          <hr className="mb-4"/>

          <div className="grid gap-4">
            <h1 className="text-3xl">
              Edit Profile
            </h1>

            <div className="flex-1">
              <LabelledPasswordInput
                label="Current Password"
                value={currentPassword}
                setValue={setCurrentPassword}
                placeholder={'Current Password'}
                errors={errorsList.currentPassword}
                hideHint={true}
              />
            </div>

            <div className="flex-1">
              <LabelledPasswordInput
                label="New Password"
                value={newPassword}
                setValue={setNewPassword}
                placeholder={'New Password'}
                errors={errorsList.newPassword}
              />
            </div>

            <div className="flex-1">
              <LabelledPasswordInput
                label="Confirm New Password"
                value={confirmNewPassword}
                setValue={setConfirmNewPassword}
                placeholder={'Confirm New Password'}
                errors={passwordDontMatch ? ["Passwords do not match."] : []}
                hideHint={true}
              />
            </div>

            <div className="pb-4 flex flex-row justify-center">
              <SubmitButton name="Submit"
                            onClick={handleSubmit}
                            isDisabled={formHasEmptyFields}/>
            </div>
          </div>
        </StoneBox>
      </div>
    </div>
  )
}

