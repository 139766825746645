import {useState} from 'react';

interface ILoginProps {
  onSubmit: (username: string, password: string) => void,
  errorsList: Record<string, string[]>
}

export const Login = ({onSubmit, errorsList}: ILoginProps) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  return (
    <div className="flex min-h-full flex-col justify-center px-6 pb-12 lg:px-8">
      <div className=''>
        <img className="mx-auto h-16 w-auto" src="./ec-logo.png" alt="EC Logo"/>
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">Log In</h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form
          className="space-y-6"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(username, password);
          }}
        >
          <div>
            <label htmlFor="username" className="block text-sm font-medium leading-6 text-white">Email</label>
            <div className="mt-2">
              <input
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                id="username"
                name="username"
                type="text"
                required
                className="px-2 block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"/>
            </div>
            {
              errorsList.email && errorsList.email.length > 0 &&
                <ul className={"text-red-500 list-disc py-1 px-4 text-xs font-medium"}>
                  {errorsList.email.map((e, index) => <li key={index}>{e}</li>)}
                </ul>
            }
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium leading-6 text-white">Password</label>
            <div className="mt-2">
              <input
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="px-2 block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"/>
            </div>
            {
              errorsList.password && errorsList.password.length > 0 &&
                <ul className={"text-red-500 list-disc py-1 px-4 text-xs font-medium"}>
                  {errorsList.password.map((e, index) => <li key={index}>{e}</li>)}
                </ul>
            }
          </div>
          <div>
            <button type="submit"
                    className="flex w-full select-none justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">Sign
              in
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
