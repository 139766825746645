import {useEffect, useState} from "react";
import {IResidentProfile} from "../../pages/RoomSettings/RoomSettingsView/Interfaces";
import _ from "lodash";
import LabelledText from "../FormElements/LabelledText";

interface IResidentProfileFormProps {
  serverState: IResidentProfile,
  onClick: (residentProfile: IResidentProfile) => void,
  roomName: string,
}

export const ResidentProfileForm = (props: IResidentProfileFormProps) => {

  const {serverState, onClick, roomName} = props;

  const [formState, setFormState] = useState<IResidentProfile>({
    firstName: serverState.firstName,
    lastName: serverState.lastName,
    dateOfBirth: serverState.dateOfBirth,
    gender: serverState.gender,
    cpsScore: serverState.cpsScore,
  });

  const isSaveButtonDisabled = _.isEqual(serverState, formState) || formState.dateOfBirth === '';

  useEffect(() => {
    setFormState(serverState);
  }, [serverState])

  function handleSubmit() {
    onClick(formState)
  }

  const handleInputChange = (field: keyof IResidentProfile, value: string) => {
    setFormState((prevState) => {
      return {...prevState, [field]: value}
    })
  }
  return <div className="flex-1 flex flex-col justify-start bg-stone-700 rounded-lg p-4 border border-stone-500">
    <h1 className="text-3xl">Personal Information</h1>
    <div className="text-2xl mt-3 flex flex-col gap-4">
      <div className="flex flex-row w-full space-between gap-4">
        <LabelledText
          label="First Name"
          value={formState.firstName}
        />
        <LabelledText
          label="Last Name"
          value={formState.lastName}
        />
      </div>


      <LabelledText
        label="Date of Birth"
        value={formState.dateOfBirth ?? ""}
      />

      <LabelledText
        label="Sex"
        value={formState.gender}
      />

      {/*<LabelledDropdown*/}
      {/*  label="CPS Score"*/}
      {/*  options={[*/}
      {/*    {*/}
      {/*      name: "0",*/}
      {/*      value: "0"*/}
      {/*    },*/}
      {/*    {*/}
      {/*      name: "1",*/}
      {/*      value: "1"*/}
      {/*    },*/}
      {/*    {*/}
      {/*      name: "2",*/}
      {/*      value: "2"*/}
      {/*    },*/}
      {/*    {*/}
      {/*      name: "3",*/}
      {/*      value: "3"*/}
      {/*    },*/}
      {/*    {*/}
      {/*      name: "4",*/}
      {/*      value: "4"*/}
      {/*    },*/}
      {/*    {*/}
      {/*      name: "5",*/}
      {/*      value: "5"*/}
      {/*    },*/}
      {/*    {*/}
      {/*      name: "6",*/}
      {/*      value: "6"*/}
      {/*    },*/}
      {/*  ]}*/}
      {/*  value={formState.cpsScore}*/}
      {/*  setValue={(value) => handleInputChange('cpsScore', value)}*/}
      {/*/>*/}

      {/*<SubmitButton*/}
      {/*  isDisabled={isSaveButtonDisabled}*/}
      {/*  name="Save Resident Profile"*/}
      {/*  onClick={handleSubmit}*/}
      {/*/>*/}
    </div>
  </div>;
}
