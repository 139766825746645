import React from 'react';

type SubmitButtonProps = {
    name: string;
    onClick: () => void;
  isDisabled?: boolean 
}

function SubmitButton({name, onClick, isDisabled}: SubmitButtonProps) {
  if (isDisabled) {
    return (
      <button
        className="mt-3 px-4 py-2 rounded bg-stone-500 text-white select-none cursor-not-allowed"
        onClick={onClick}
        disabled={isDisabled}
      >
        {name}
      </button>
    );
  }
    return (
        <button
          className="mt-3 px-4 py-2 rounded bg-blue-500 hover:bg-blue-800 active:bg-blue-950 text-white select-none"
          onClick={onClick}
        >
            {name}
        </button>
    );
}

export default SubmitButton;
