import {RoomStatus} from "../../HomeArea/Interfaces";
import {getStatusIcon} from "../../../components/Room/StatusIcons/GetStatusIcon";
import {useMemo} from "react";

const getHumanName = (name: RoomStatus) => {
  if (name === 'fall') {
    return 'falls'
  }
  return name.replaceAll("-", " ")
}

const makeHumanMinutes = (value: number) => {
  const minutes = Math.floor(value);
  const partialMinute = value - minutes;
  const seconds = Math.floor(partialMinute * 60);

  return `${minutes}m ${seconds}s`
}

const makeHumanHours = (value: number) => {
  const hours = Math.floor(value);
  const partialHour = value - hours;
  const minutes = Math.floor(partialHour * 60);

  return `${hours}h ${minutes}m`
}

type Unit = 'hours' | 'minutes'

export const Stat = ({name, value, unit}: { name: RoomStatus, value: number, unit?: Unit }) => {
  const humanValue: string = useMemo((): string => {
    if (value < 0) {
      return value.toString();
    }
    if (unit === 'hours') {
      return makeHumanHours(value);
    }
    if (unit === 'minutes') {
      return makeHumanMinutes(value);
    }
    return value.toString();
  }, [value, unit]);

  return <div
    className='flex border border-stone-500 rounded-lg px-4 py-2.5 bg-gradient-to-b from-stone-800 to-transparent'>
    <div className='w-12 h-12 mr-7'>
      {getStatusIcon(name)}
    </div>
    <div>
      <div
        className="capitalize text-sm text-white text-opacity-50 font-semibold">{getHumanName(name)}</div>
      <div className="text-lg font-medium">{humanValue}</div>
    </div>
  </div>
}