import {useEffect, useState} from 'react';
import {
  getExceptionThresholdOptions,
  getFacilitySettings,
  getHomeAreas,
  updateFacilitySettings
} from '../../services/httpService';
import {IExceptionThresholdGroup, IFacilitySettings} from "./Interfaces";
import {FacilitySettingsForm} from "./FacilitySettingsForm";
import {useNavigate} from "react-router-dom";
import {NavigateBackButton} from "../../components/Buttons/NavigateBackButton";
import {TwoColumns} from "../../components/Layouts/TwoColumns";
import {StoneBox} from "../../components/Layouts/StoneBox";
import {ProtectedView} from '../../components/Layouts/ProtectedView';
import {useNotification} from "../../context/NotificationContext";

const FacilitySettingsPage = () => {
  const navigate = useNavigate();
  const [facilitySettings, setFacilitySettings] = useState<IFacilitySettings | null>(null);
  const [exceptionThresholdOptions, setExceptionThresholdOptions] = useState<IExceptionThresholdGroup | null>(null)
  const {showSuccess, showError} = useNotification();

  const fetchFacilityData = (async () => {
    try {
      const facilityData = await getFacilitySettings();
      setFacilitySettings(facilityData)
      const exceptionThresholdOptionsData = await getExceptionThresholdOptions()
      setExceptionThresholdOptions(exceptionThresholdOptionsData);
    } catch (e) {

    }
  });

  const handleBackToHomeArea = async () => {
    const homeAreas = await getHomeAreas();
    const firstHomeArea = homeAreas[0].id
    navigate(`/home-areas/${firstHomeArea}`);
  }
  const handleSubmit = async (settings: IFacilitySettings) => {
    try {
      const res = await updateFacilitySettings(settings);
      showSuccess(res.message);
      setFacilitySettings(settings);
    } catch (e: any) {
      if (e.response?.status === 400) {
        showError(e.response.data.message);
      }
    }
  }

  useEffect(() => {
    fetchFacilityData().catch();
  }, []);

  const sideContent = <StoneBox>
    <div className="flex flex-col">
      <NavigateBackButton onClick={handleBackToHomeArea} label='Back to Home View'/>
      <hr className="mb-4"/>
      <button
        className="text-left hover:text-blue-500" onClick={() => navigate('/settings')}>
        Facility Settings
      </button>
      <ProtectedView roles={['admin']}>
        <button
          className="text-left hover:text-blue-500" onClick={() => navigate('/settings/devices')}>
          Device Settings
        </button>
      </ProtectedView>
      <ProtectedView roles={['admin']}>
        <button
          className="text-left hover:text-blue-500" onClick={() => navigate('/settings/users')}>
          User Settings
        </button>
      </ProtectedView>
    </div>

  </StoneBox>


  const mainContent = <StoneBox>
    <div className="text-3xl text-white">
      Facility Settings
    </div>
    {facilitySettings && exceptionThresholdOptions &&
    <FacilitySettingsForm
        initialState={facilitySettings}
        submit={handleSubmit}
        exceptionThresholdOptions={exceptionThresholdOptions}
    />
    }
  </StoneBox>

  return (
    <TwoColumns side={sideContent} main={mainContent}/>
  );
}

export default FacilitySettingsPage;
