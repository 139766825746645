import React from 'react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  TooltipItem,
} from 'chart.js';
import {Bar, Line} from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

function formatHoursForHumans(value: number) {
  const hours = Math.floor(value);
  const minutes = Math.round((value - hours) * 60);

  return hours + 'h ' + minutes + 'm';
}

function formatMinutesForHumans(value: number) {
  const minutes = Math.floor(value);
  const seconds = Math.round((value - minutes) * 60);

  return minutes + 'm ' + seconds + 's';
}

function formatForHumans(value: string, metricType: any) {
  const valueAsNumber = +value;
  if (isNaN(valueAsNumber)) {
    return value;
  }

  switch (metricType) {
    case "Sedentary Hours":
      return formatHoursForHumans(valueAsNumber);
    case "Active Hours":
      return formatHoursForHumans(valueAsNumber);
    case "Toileting Duration":
      return formatMinutesForHumans(valueAsNumber);
    case "Bed Exit Routine":
      return formatMinutesForHumans(valueAsNumber);
  }
  return value;
}

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  text: {
    color: 'white',
  },
  scales: {
    x: {
      ticks: {
        color: "white",
        font: {
          size: 14
        }
      },
      grid: {
        color: "rgb(92,87,84)"
      }
    },
    y: {
      min: 0,
      max: 25,
      ticks: {
        color: "white",
        font: {
          size: 14
        }
      },
      grid: {
        color: "rgb(92,87,84)"
      },
    }
  },
  plugins: {
    legend: {
      position: 'bottom' as const,
      labels: {
        color: 'white',
        font: {
          size: 14
        }
      }
    },
    tooltip: {
      titleFont: {
        size: 18
      },
      bodyFont: {
        size: 16
      },
      callbacks: {
        label: function (tooltipItem: TooltipItem<"bar" | "line">) {
          const value = tooltipItem.formattedValue;
          const metricType = tooltipItem.dataset.label || "";


          return metricType + ": " + formatForHumans(value, metricType);
        }
      }
    }
  },
};

interface IDataset {
  label: string;
  data: number[]
}

export interface IChartGraphData {
  labels: string[];
  datasets: IDataset[]
}

type ChartType = 'line' | 'bar';

export function Chart({chartType, data}: { chartType: ChartType, data: IChartGraphData }) {
  const colors = [
    "#5B9BD5",
    "#7bf37b",
    "rgb(57, 162 , 195)",
    "rgb(185, 191 , 79)",
    "rgb(187, 107, 195)",
    "rgb(75, 191, 195)",
    "rgb(119, 191, 103)",
  ];

  const normalizedData = {
    labels: data.labels,
    datasets: data.datasets.map((dataset: any, index: number) => {
      return {
        label: dataset.label,
        data: dataset.data,
        borderColor: colors[index],
        backgroundColor: colors[index],
        labelColor: 'white',
        lineTension: 0.33,
      };
    })
  };
  
  if (chartType === 'bar') {
    return <Bar options={options} data={normalizedData}/>;
  }

  return <Line options={options} data={normalizedData}/>;
}
