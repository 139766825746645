import {StoneBox} from "../../../components/Layouts/StoneBox";
import {LabelledDateInput} from "../../../components/FormElements/LabelledDateInput";
import {useEffect, useState} from "react";
import {getIncidentSummary, IIncidentSummary} from "../../../services/httpService";
import {useParams} from "react-router-dom";
import {IncidentStat} from "./IncidentStat";
import {Spinner} from "../../../components/Icons/Spinner";


export const IncidentSummary = () => {
  const {homeAreaId} = useParams() as { homeAreaId: string };
  const [incidentData, setIncidentData] = useState<null|IIncidentSummary>(null);
  const [date, setDate] = useState(() => getYesterdayDate());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchIncidentSummary = async () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false)
      }, 800);
      const summary = await getIncidentSummary(homeAreaId, date);
      setIncidentData(summary);
    }

    fetchIncidentSummary().catch();
  }, [homeAreaId, date])

  function getYesterdayDate() {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return date.toISOString().split('T')[0];
  }


  return <StoneBox>
    <div className="flex flex-col flex-1">
      <h2 className="text-3xl mb-3">Incident Summary</h2>
      <div className="mb-3.5">
        <LabelledDateInput label={"Date"} setValue={setDate} value={date} maxDate={getYesterdayDate()}/>
      </div>
    </div>
    {
      (incidentData && !loading)
        ? <div className="flex flex-col gap-3.5">
          <IncidentStat incidentName={"fall"} incidentValue={incidentData?.falls.toString() ?? ''} />
          <IncidentStat incidentName={"out-of-bed"} incidentValue={incidentData?.outOfBedOccurrences.toString() ?? ''} />
          <IncidentStat incidentName={"out-of-room"} incidentValue={incidentData?.outOfRoomOccurrences.toString() ?? ''} />
          <IncidentStat incidentName={"no-movement"} incidentValue={incidentData?.noMovementDetectedOccurrences.toString() ?? ''} />
          <IncidentStat incidentName={"bed-exit"} incidentValue={incidentData?.bedExitAttempts.toString() ?? ''} />
        </div>
        : <Spinner />

    }
  </StoneBox>
}
