import * as React from 'react'
import {IDevice} from './index';
import {DeviceRow} from "./DeviceRow";

interface DevicesTableProps {
  data: IDevice[],
  handleEditDevice: (deviceId: string) => void,
  handleActivateDevice: (deviceId: string) => void,
  handleTestNotificationDevice: (deviceId: string) => void
}

export function DevicesTable(props: DevicesTableProps) {
  const {
    data,
    handleEditDevice,
    handleActivateDevice,
    handleTestNotificationDevice
  } = props;

  return (
    <div className="mt-4">
      <table className="min-w-full divide-y divide-stone-300">
        <thead>
          <tr>
            {['Name', 'Home Area', 'Role', 'Last Active', 'Access Level'].map((header,index) => {
              return <th key={index} className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-white sm:pl-0">
                <span>{header}</span>
              </th>
            })}
          </tr>
        </thead>
        <tbody className='divide-y divide-stone-300'>
        {
          data.map(device =>
            <DeviceRow
              device={device}
              onTestClick={() => handleTestNotificationDevice(device.id)}
              onEditClick={() => handleEditDevice(device.id)}
              onActivateLinkClick={() => handleActivateDevice(device.id)}
              key={device.id}
            />
        )}
        </tbody>
      </table>
    </div>
  )
}

