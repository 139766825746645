import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {
  getResidentProfile,
  getResidentSettings,
  getRoom,
  updateResidentProfile,
  updateResidentSettings,
  vacateRoom
} from '../../services/httpService';
import {RoomSettingsView} from './RoomSettingsView/RoomSettingsView';
import {IResidentProfile} from "./RoomSettingsView/Interfaces";
import {IDetailedRoom} from "../HomeArea/Interfaces";
import {useNotification} from "../../context/NotificationContext";

export interface IRoomSettings {
  highRiskForFalls: boolean,
  requiresMultiplePersonAssistanceForBedExitAndToileting: boolean,
  alertAttemptedBedExit: boolean
  alertOutOfBedMorning: boolean,
  alertOutOfBedAfternoon: boolean,
  alertOutOfBedNight: boolean,
  alertOutOfRoomMorning: boolean,
  alertOutOfRoomAfternoon: boolean,
  alertOutOfRoomNight: boolean,

  alertNoMovementDetectedMorning: boolean,
  alertNoMovementDetectedAfternoon: boolean,
  alertNoMovementDetectedNight: boolean,
}

const RoomSettingsPage = () => {
  const navigate = useNavigate();
  const {roomId} = useParams() as { roomId: string };
  const [isLoading, setIsLoading] = useState(false)
  const [room, setRoom] = useState<IDetailedRoom | null>(null)
  const [residentProfile, setResidentProfile] = useState<IResidentProfile | null>(null)
  const [is404, setIs404] = useState(false)
  const [roomSettings, setRoomSettings] = useState<IRoomSettings | null>(null);
  const {showSuccess, showError} = useNotification();

  const fetchBedData = async () => {
    try {
      console.log('fetching room data')
      const roomData: IDetailedRoom = await getRoom(roomId);
      setRoom(roomData)
      console.log("API returns:", roomData);
      if (!roomData) {
        throw new Error('no bed data')
      }
      return roomData
    } catch (e) {
      // @ts-ignore
      if (e.response.status === 404) {
        setIs404(true)
      }
      console.error({e})
      throw new Error('no bed data')
    }
  };

  const fetchResidentSettings = async (residentId: string | null) => {
    try {
      if (!residentId) {
        setRoomSettings(null);
        return
      }
      const roomSettingsData: IRoomSettings = await getResidentSettings(residentId);
      setRoomSettings(roomSettingsData);
    } catch (e) {

    }
  }

  const fetchResidentProfile = async (residentId: string | null) => {
    try {
      if (!residentId) {
        setRoomSettings(null);
        return
      }
      const data: IResidentProfile = await getResidentProfile(residentId);
      setResidentProfile(data);
    } catch (e) {

    }
  }

  useEffect(() => {
    setIsLoading(true)
    fetchBedData().then((bedData: IDetailedRoom) => {
      const promise1 = fetchResidentSettings(bedData.resident_id).catch();
      const promise2 = fetchResidentProfile(bedData.resident_id).catch();

      Promise.all([promise1, promise2]).then(() => {
        setIsLoading(false);
      });
    }).catch(() => {
      console.error('no bed data')
    })


  }, []);

  if (is404) {
    return <div className="h-full flex items-center justify-center text-white text-3xl">404: Room is
      not found</div>
  }
  if (isLoading || !room || !roomSettings || !residentProfile) {
    return <div className="h-full flex items-center justify-center text-white text-3xl">Loading</div>
  }

  const handleSubmitForm = async (settings: IRoomSettings) => {
    try {
      if (!room.resident_id) {
        window.alert('Cannot create settings on a vacant room.')
        return;
      }
      const res = await updateResidentSettings(room.resident_id, settings);
      showSuccess(res.message)
      setRoomSettings(settings);
    } catch (e: any) {
      if (e.response?.status === 400) {
        showError(e.response.data.message);
      }
    }
  }

  const onPersonalInfoSubmit = async (residentProfile: IResidentProfile) => {
    try {
      if (!room.resident_id) {
        window.alert('Cannot update vacant rooms')
        return;
      }
      const res = await updateResidentProfile(room.resident_id, residentProfile);
      showSuccess(res.message)
      setResidentProfile(residentProfile)
    } catch (e: any) {
      if (e.response?.status === 400) {
        showError(e.response.data.message);
      }
    }

  }

  const handleVacateRoomBtnClick = async () => {
    if (window.confirm('Warning: Do you really want to vacate this room?')) {
      try {
        await vacateRoom(roomId);
        setResidentProfile((previous) => {
          if (!previous) {
            return null;
          }

          return {
            ...previous,
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            gender: 'female',
            cpsScore: '0',
          }
        })
        setRoomSettings((previous) => {
          if (!previous) {
            return null;
          }
          return {
            ...previous,
            highRiskForFalls: false,
            requiresMultiplePersonAssistanceForBedExitAndToileting: false,
            alertAttemptedBedExit: false,
            alertOutOfBedMorning: false,
            alertOutOfBedAfternoon: false,
            alertOutOfBedNight: false,
            alertOutOfRoomMorning: false,
            alertOutOfRoomAfternoon: false,
            alertOutOfRoomNight: false,
            alertNoMovementDetectedMorning: false,
            alertNoMovementDetectedAfternoon: false,
            alertNoMovementDetectedNight: false,
          }
        })
        setRoom((previous) => {
          if (!previous) {
            return null;
          }

          return {
            ...previous,
            status: 'vacant'
          }
        })
      } catch (e: any) {

      }
    }
  }

  const handleManageAbsenceBtnClick = () => {
    navigate(`/rooms/${roomId}/settings/absence`);
  }

  const handleTransferBtnClick = () => navigate(`/rooms/${roomId}/settings/transfer`);


  return (
    <RoomSettingsView
      onBackBtnClick={() => navigate(`/rooms/${roomId}`)}
      room={room}
      onSubmit={handleSubmitForm}
      roomSettings={roomSettings}
      residentProfile={residentProfile}
      onPersonalInfoSubmit={onPersonalInfoSubmit}
      vacateRoomBtnClick={handleVacateRoomBtnClick}
      handleTransferBtnClick={handleTransferBtnClick}
      handleManageAbsenceBtnClick={handleManageAbsenceBtnClick}
    />
  );
}

export default RoomSettingsPage
