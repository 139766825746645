import {StoneBox} from "../../../components/Layouts/StoneBox";
import {useEffect, useState} from "react";
import {Spinner} from "../../../components/Icons/Spinner";
import {LabelledDateInput} from "../../../components/FormElements/LabelledDateInput";
import {IDailySummary} from "../../HomeArea/Interfaces";
import {Stat} from "./Stat";

function getYesterdayDate() {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return date.toISOString().split('T')[0];
}

interface DailySummaryProps {
  data: IDailySummary | null | undefined;
  date: string;
  setDate: (date: string) => void;
}

function DateOrMessage(props: { data: IDailySummary | null | undefined }) {
  return <>
    {!props.data
      ? <div>No data available</div>
      : <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-1 gap-3.5">
        <Stat name="sedentary" unit="hours" value={props.data.sedentary}/>
        <Stat name="walking" unit="hours" value={props.data.walking}/>
        <Stat name="active" unit="hours" value={props.data.active}/>
        <Stat name="in-washroom" unit="minutes" value={props.data.inWashroom}/>
        <Stat name="in-bed" unit="hours" value={props.data.inBed}/>
        <Stat name="out-of-room" unit="hours" value={props.data.outOfRoom}/>
        <Stat name="fall" value={props.data.falls}/>
      </div>}
  </>;
}

export const DailySummary = (props: DailySummaryProps) => {
  const {
    data,
    date,
    setDate
  } = props;

  const [showLoader, setShowLoader] = useState(false);

  const handleSetValue = (date: string) => {
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
    }, 5 * 1000);
    setDate(date);
  }

  useEffect(() => {
    console.log('Data prop has changed')
    setShowLoader(false)
  }, [data])

  return <StoneBox>
    <div className="flex flex-col">
      <h2 className="text-3xl mb-3">Daily Summary</h2>
      <div className="mb-3.5">
        <LabelledDateInput label={"Date"} setValue={handleSetValue} value={date} maxDate={getYesterdayDate()}/>
      </div>
      {
        showLoader
          ? <div><Spinner/></div>
          : <DateOrMessage data={data}/>
      }
    </div>
  </StoneBox>
}


