import LabelledTextInput from '../../components/FormElements/LabelledTextInput';
import {useEffect, useMemo, useReducer, useState} from 'react';
import {getHomeAreas, postNewDevice} from '../../services/httpService';
import SubmitButton from '../../components/FormElements/SubmitButton';
import {useNavigate} from 'react-router-dom';
import {NavigateBackButton} from "../../components/Buttons/NavigateBackButton";
import LabelledDropdown from "../../components/FormElements/LabelledDropdown";
import {MessagePage} from "../../components/Layouts/MessagePage";
import {StoneBox} from "../../components/Layouts/StoneBox";
import {Role} from '../../context/IAuthContext';
import _ from 'lodash';
import {IHomeArea} from "../HomeArea/Interfaces";
import {useNotification} from "../../context/NotificationContext";
import LabelledNumberDropdown from "../../components/FormElements/LabelledNumberDropdown";

interface IDeviceReducerState {
  name: string;
  accessLevel: number,
  homeAreaId: string;
  role: Role
}

const reducer = (state: IDeviceReducerState, action: { type: string, value: string | number, key: string } | {
  type: 'reset'
}) => {
  switch (action.type) {
    case 'reset':
      return {...state, name: ''}
    case 'onChange':
      return {...state, [action.key]: action.value}
    default:
      return state;
  }
}
export const CreateDevice = () => {
  const initialCreateDeviceState: IDeviceReducerState = useMemo<IDeviceReducerState>(() => {
    return {
      name: '',
      id: '',
      accessLevel: 1,
      homeAreaId: '',
      role: 'viewer' as Role
    }
  }, []);
  const navigate = useNavigate();
  const [homeAreas, setHomeAreas] = useState<IHomeArea[] | null>()
  const [state, dispatch] = useReducer(reducer, initialCreateDeviceState);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const {showSuccess, showError} = useNotification()

  useEffect(() => {
    if (!state.name) {
      return setIsSubmitButtonDisabled(true);
    }
    if (!_.isEqual(initialCreateDeviceState, state)) {
      setIsSubmitButtonDisabled(false)
    } else {
      setIsSubmitButtonDisabled(true)
    }
  }, [initialCreateDeviceState, state]);
  
  useEffect(() => {
    (async () => {
      const fetchedHomeAreas = await getHomeAreas()
      setHomeAreas(fetchedHomeAreas)
      dispatch({type: 'onChange', key: "homeAreaId", value: fetchedHomeAreas[0].id});
    })()
  }, []);

  const handleInputChange = (value: string | number, name: keyof IDeviceReducerState) => {
    dispatch({type: 'onChange', key: name, value: value});
    console.log(state);
  }
  
  const handleStoreDevice = async () => {
    try {
      await postNewDevice({
        name: state.name,
        accessLevel: state.accessLevel,
        homeAreaId: state.homeAreaId,
        role: state.role,
      })
      showSuccess("Successfully added device!")
      navigate('/settings/devices');
    } catch (e: any) {
      if (e.response?.status === 400) {
        showError(e.response.data.message);
      }
    }
  }

  const resetForm = () => {
    dispatch({type: 'reset'});
  }

  const handleStoreAndCreateAnotherDevice = async () => {
    try {
      await postNewDevice({
        name: state.name,
        accessLevel: state.accessLevel,
        homeAreaId: state.homeAreaId,
        role: state.role,
      })
      resetForm();
      showSuccess("Successfully added device!")
    } catch (e: any) {
      if (e.response?.status === 400) {
        showError(e.response.data.message);
      }
    }
  }

  if (!homeAreas) {
    return <MessagePage message="Loading..."/>
  }

  return (
    <div className="flex justify-center mt-16">
      <div
        className="w-3/4 lg:w-1/2 text-white flex flex-col justify-start gap-4">
        <StoneBox>
          <NavigateBackButton onClick={() => navigate('/settings/devices')} label={"Back to Devices Management"}/>
          <hr className="mb-4"/>
          <h1 className="text-3xl">
            Add a new device
          </h1>

          <div className="pt-4 flex flex-row w-full space-between gap-4">
            <div className="flex-1">
              <LabelledTextInput
                label="Device Name"
                value={state.name}
                setValue={(value) => handleInputChange(value, 'name')}
                placeholder={'New Device Name'}
              />
            </div>

            <div className="flex-1">
              <LabelledNumberDropdown
                label="Access Level"
                value={state.accessLevel}
                setValue={(value) => handleInputChange(value, 'accessLevel')}
                options={[
                  {
                    name: '1',
                    value: 1,
                  },
                  {
                    name: '2',
                    value: 2,
                  },
                  {
                    name: '3',
                    value: 3,
                  }
                ]}
              />
            </div>

            <div className="flex-1 text-md">
              <LabelledDropdown
                label="Home Area"
                value={state.homeAreaId}
                options={homeAreas.map((area) => {
                  return {name: area.name, value: area.id}
                })}
                setValue={(value: string) => handleInputChange(value, 'homeAreaId')}
              />
            </div>

            <div className="flex-1 text-md">
              <LabelledDropdown
                label="Role"
                value={state.role}
                options={[
                  {
                    name: 'Viewer',
                    value: 'viewer',
                  },
                  {
                    name: 'Manager',
                    value: 'manager',
                  },
                  {
                    name: 'Admin',
                    value: 'admin',
                  },
                ]}
                setValue={(value: string) => handleInputChange(value, 'role')}
              />
            </div>

          </div>

          <div className="pt-4 pb-4 flex flex-row gap-4">
            <SubmitButton name="Create Device" onClick={handleStoreDevice} isDisabled={isSubmitButtonDisabled}/>

            <SubmitButton name="Create & Add Another" onClick={handleStoreAndCreateAnotherDevice}
                          isDisabled={isSubmitButtonDisabled}/>
          </div>

        </StoneBox>
      </div>
    </div>
  )
}
