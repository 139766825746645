import {getStatusIcon} from "../../../components/Room/StatusIcons/GetStatusIcon";
import {RoomStatus} from "../../HomeArea/Interfaces";

interface IIncidentStatProps {incidentName: RoomStatus, incidentValue: string}
export const IncidentStat = (props: IIncidentStatProps) => {
  const {incidentName, incidentValue} = props;

  const getHumanName = (name: RoomStatus) => {
    if (name === 'fall') {
      return 'falls'
    }
    return name.replaceAll("-", " ")
  }

  return <div
    className='flex border border-stone-500 rounded-lg px-4 py-2.5 bg-gradient-to-b from-stone-800 to-transparent'>
    <div className='w-12 h-12 mr-7'>
      {getStatusIcon(incidentName)}
    </div>
    <div>
      <div
        className="capitalize text-sm text-white text-opacity-50 font-semibold">{getHumanName(incidentName)}</div>
      <div className="text-lg font-medium text-white">{incidentValue}</div>
    </div>
  </div>
}