import React from 'react';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";

export type LabelledDateInputProps = {
  label: string;
  value?: string;
  setValue: (value: string) => void;
    defaultValue?: string;
  maxDate?: string;
}


export function LabelledDateInput({label, value, setValue, defaultValue, maxDate}: LabelledDateInputProps) {

  return (
    <label className="block w-full text-md font-medium leading-6 text-white">
      {label}
      <Flatpickr
        className="block w-full rounded-md border-0 py-2 px-2 text-stone-900 shadow-sm ring-1 ring-inset ring-stone-300 placeholder:text-stone-400 focus:ring-2 focus:ring-inset focus:ring-stone-600 sm:text-sm sm:leading-6"
        options={{
            defaultDate: defaultValue,
          allowInput: true,
          maxDate: maxDate,
        }}
        value={value}
        onClose={([date]: Array<Date>) => setValue(date?.toISOString().split('T')[0] || '')}
        placeholder="yyyy-mm-dd"
        required={true}
      />
    </label>
  );
}