import {useMemo,} from "react";
import {getStatusIcon} from "./StatusIcons/GetStatusIcon";

import {IBaseRoom} from "../../pages/HomeArea/Interfaces";

type RoomPanelProps = {
  onClick: () => void;
  room: IBaseRoom;
}

function RoomPanel({ onClick, room }: RoomPanelProps) {
  let classes = "p-4 rounded-lg cursor-pointer text-center text-lg flex gap-4 items-center justify-start flex-col font-bold"
  if (room.alert) {
    classes += " bg-red-500 hover:bg-red-600 text-red-50"
  } else {
    classes += " hover:bg-gray-700 text-stone-100"
  }

  const icon = useMemo(() => {
    return getStatusIcon(room.status)
  }, [room])


  return (
    <div
      className={classes}
      onClick={onClick}
    >
      <div className="h-12 w-12">
        {icon}
      </div>
      <div>{room.display_name}</div>
      <div className="text-xs capitalize">{room.status ? room.status.replaceAll('-', ' ') : 'Unknown'}</div>
    </div>);
}

export default RoomPanel;
