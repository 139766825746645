import {IDevice} from "./index";
import * as React from "react";
import {LatestActivityBadge} from "./LatestActivityBadge";


export function DeviceRow({device, onTestClick, onEditClick, onActivateLinkClick}: {
  device: IDevice,
  onTestClick: () => void,
  onEditClick: () => void,
  onActivateLinkClick: () => void,
}) {
  const renderDeviceId = () => {
    if (!device.lastActiveAt) {
      return <td className="whitespace-nowrap pr-3 py-4 text-lg text-yellow-300 italic font-medium">
        Registration Pending
        <button type="button"
                className="ml-3 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={onActivateLinkClick}>
          Activate Now
        </button>
      </td>
    }
    return (
      <td>
        <LatestActivityBadge date={device.lastActiveAt}/>
      </td>);
  }
  return <tr>
    <td className="whitespace-nowrap pr-3 py-4 text-lg text-white">{device.name}</td>
    <td className="whitespace-nowrap pr-3 py-4 text-lg text-white">{device.homeArea.name}</td>
    <td className="whitespace-nowrap pr-3 py-4 text-lg text-white">{device.role}</td>
    {renderDeviceId()}
    <td className="whitespace-nowrap pr-3 py-4 text-lg text-white">{device.accessLevel}</td>
    <td
      className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right items-end justify-end text-sm font-medium sm:pr-0">
      <button className="text-stone-400 hover:text-stone-300 mr-4" onClick={onTestClick}>
        Test
      </button>

      <button className="text-stone-400 hover:text-stone-300 mr-4" onClick={onActivateLinkClick}>
        View
      </button>

      <button className="text-stone-400 hover:text-stone-300" onClick={onEditClick}>
        Edit
      </button>
    </td>
  </tr>;
}