import React from 'react';

type SubmitButtonProps = {
  name: string;
  onClick: () => void;
  isDisabled?: boolean
}

function SecondaryButton({name, onClick, isDisabled}: SubmitButtonProps) {
  return (
    <button
      className="px-4 py-2 rounded border-2 bg-stone-600 border-stone-400 text-stone-200 select-none hover:bg-blue-300 hover:border-blue-300 hover:text-blue-950"
      onClick={onClick}
    >
      {name}
    </button>
  );
}

export default SecondaryButton;
