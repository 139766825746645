import {ReportingPeriod, RoomView} from './RoomView/RoomView';
import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {getDailySummary, getResidentProfile, getRoom, getRoomCharts} from '../../services/httpService';
import {MessagePage} from "../../components/Layouts/MessagePage";
import {IResidentProfile} from '../RoomSettings/RoomSettingsView/Interfaces';
import {IChart, IDailySummary, IDetailedRoom} from "../HomeArea/Interfaces";

function getYesterdayDate() {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return date.toISOString().split('T')[0];
}

const RoomPage = () => {
  const {bedId} = useParams() as { bedId: string };
  const navigate = useNavigate();
  const [bed, setBed] = useState<IDetailedRoom | null>(null)
  const [charts, setCharts] = useState<IChart[] | undefined>([])
  const [is404, setIs404] = useState(false)
  const [reportingPeriod, setReportingPeriod] = useState<ReportingPeriod>('day');
  const [residentData, setResidentData] = useState<IResidentProfile | null>(null);
  const [residentId, setResidentId] = useState<string | null>(null);
  const [dailySummaryData, setDailySummaryData] = useState<IDailySummary | null | undefined>(null)
  const [dailySummaryDate, setDailySummaryDate] = useState<string>(getYesterdayDate());
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchBedData().then((bedData: IDetailedRoom) => {
      setResidentId(bedData.resident_id)
      const promise1 = fetchDailySummary(bedData.resident_id)
      const promise2 = fetchResidentCharts(bedData.resident_id)
      const promise3 = fetchResidentData(bedData.resident_id)

      Promise.all([promise1, promise2, promise3]).then(() => {
        setIsLoading(false)
      });
    }).catch(() => console.error('could not find bed'));
    const setter = setInterval(fetchBedData, 30 * 1000)

    return () => {
      clearInterval(setter)
    }
  }, [bedId]);

  // useEffect(() => {
  //   if (!residentId) {
  //     return;
  //   }
  //   console.log('an expensive resident data retrieval')
  //   fetchResidentData(residentId).catch()
  // }, [residentId])

  useEffect(() => {
    if (!residentId) {
      return;
    }
    console.log('an expensive daily summary retrieval')
    fetchDailySummary(residentId).catch()
  }, [dailySummaryDate])

  useEffect(() => {
    if (!residentId) {
      return
    }
    console.log('an expensive charts retrieval')
    fetchResidentCharts(residentId).catch();
  }, [reportingPeriod])


  const fetchBedData = (async () => {
    try {
      console.log('fetching room data')
      const bedData: IDetailedRoom = await getRoom(bedId);
      setBed(bedData)
      return bedData
    } catch (e) {
      // @ts-ignore
      if (e.response.status === 404) {
        setIs404(true)
      }
      console.error({e})
      throw new Error('Could not find bed')
    }
  });

  const fetchDailySummary = async (residentId: string | null) => {
    try {
      if (!residentId) {
        return
      }
      const dailySummaryData: IDailySummary = await getDailySummary(residentId, dailySummaryDate);
      setDailySummaryData(dailySummaryData);
    } catch (e) {
      console.error({e})
      // todo: ensure dailySummary is set to null, right now it goes back to the previous data, but the date changes
      // @ts-ignore
      // if (e.response.status === 404) {
      //   setIs404(true)
      // }
    }
  };

  const fetchResidentCharts = async (residentId: string | null) => {
    if (!residentId) {
      setResidentData(null);
      return;
    }
    const chartData: IChart[] = await getRoomCharts(residentId, reportingPeriod);
    setCharts(chartData)
  };

  const fetchResidentData = async (residentId: string | null) => {
    if (!residentId) {
      setResidentData(null);
      return;
    }
    const residentResponse = await getResidentProfile(residentId);
    setResidentData(residentResponse);
  }

  if (is404) {
    return <MessagePage message='404: Room is not found'/>
  }

  if (!bed || isLoading) {
    return <MessagePage message='Loading...'/>
  }

  const handleRoomSettingClick = () => {
    navigate(`/rooms/${bedId}/settings`)
  }

  return (
    <RoomView
      room={bed}
      charts={charts}
      resident={residentData}
      reportingPeriod={reportingPeriod}
      onReportingPeriodChange={(period) => {
        setReportingPeriod(period);
      }}
      onHomeAreaButton={() => {
        navigate(`/home-areas/${bed.homeArea.id}`)
      }}
      onRoomSettingsClick={handleRoomSettingClick}
      dailySummaryData={dailySummaryData}
      dailySummaryDate={dailySummaryDate}
      setDailySummaryDate={setDailySummaryDate}
    />
  )
}

export default RoomPage
