import {useEffect, useState} from 'react';
import {getDevices, getHomeAreas, triggerTestNotification} from '../../services/httpService';
import {DevicesTable} from './DevicesTable';
import {useNavigate} from 'react-router-dom';
import {NavigateBackButton} from "../../components/Buttons/NavigateBackButton";
import {TwoColumns} from "../../components/Layouts/TwoColumns";
import {StoneBox} from "../../components/Layouts/StoneBox";
import {Role} from '../../context/IAuthContext';
import {IHomeArea} from "../HomeArea/Interfaces";
import {ProtectedView} from "../../components/Layouts/ProtectedView";
import {useNotification} from "../../context/NotificationContext";

export interface IDevice {
  name: string;
  id: string;
  accessLevel: number;
  deviceId?: string;
  homeArea: IHomeArea;
  role: Role;
  lastActiveAt?: string | null;
}

const DeviceManagement = () => {
  const navigate = useNavigate();
  const {showSuccess, showError} = useNotification();

  const [devices, setDevices] = useState<IDevice[] | null>(null);
  const fetchDevices = (async () => {
    try {
      const devicesList = await getDevices();
      setDevices(devicesList)
    } catch (e) {

    }
  });
  useEffect(() => {
    fetchDevices().catch();
  }, []);

  const handleTestButton = async (deviceId: string) => {

    if (window.confirm("This will send a test push notification to the device. Are you sure?")) {
      try {
        const notificationResponse = await triggerTestNotification(deviceId);
        showSuccess(notificationResponse.message);
      } catch (e: any) {
        showError(e.response.data.message)
      }

    }
  }

  const navigateToDeviceEditScreen = (deviceId: string) => {
    navigate(`/settings/devices/${deviceId}/edit`)
  }

  const navigateToDeviceActivateScreen = (deviceId: string) => {
    navigate(`/settings/devices/${deviceId}/activate`)
  }

  const handleBackToHomeArea = async () => {
    const homeAreas = await getHomeAreas();
    const firstHomeArea = homeAreas[0].id
    navigate(`/home-areas/${firstHomeArea}`);
  }

  const sideContent = <StoneBox>
    <div className="flex flex-col justify-start">
      <NavigateBackButton onClick={handleBackToHomeArea} label='Back to Home View'/>
      <hr className="mb-4"/>
      <ProtectedView roles={['admin']}>
        <button
          className="text-left hover:text-blue-500" onClick={() => navigate('/settings')}>
          Facility Settings
        </button>
      </ProtectedView>
      <ProtectedView roles={['admin']}>
        <button
          className="text-left hover:text-blue-500" onClick={() => navigate('/settings/devices')}>
          Device Settings
        </button>
      </ProtectedView>
      <ProtectedView roles={['admin']}>
        <button
          className="text-left hover:text-blue-500" onClick={() => navigate('/settings/users')}>
          User Settings
        </button>
      </ProtectedView>
    </div>
  </StoneBox>

  const mainContent =
    <StoneBox>
      <div className="flex-1 flex flex-col justify-start">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl text-white">Manage Devices</h1>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block select-none rounded-md bg-stone-500 px-3 py-2 text-center text-sm font-semibold text-white hover:bg-stone-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-500"
              onClick={() => navigate('/settings/devices/new')}
            >
              Add device
            </button>
          </div>
        </div>

        {devices && <DevicesTable
          data={devices}
          handleEditDevice={navigateToDeviceEditScreen}
          handleActivateDevice={navigateToDeviceActivateScreen}
          handleTestNotificationDevice={handleTestButton}
        />}
      </div>
    </StoneBox>


  return (
    <TwoColumns side={sideContent} main={mainContent}/>
  )
}


export default DeviceManagement;
