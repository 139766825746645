import {SedentaryIcon} from "./SedentaryIcon";
import {OutOfRoomIcon} from "./OutOfRoomIcon";
import {InBedIcon} from "./InBedIcon";
import {WashRoomIcon} from "./WashRoomIcon";
import {FallIcon} from "./FallIcon";
import {ActiveIcon} from "./ActiveIcon";
import {WalkingIcon} from "./WalkingIcon";
import {VacantIcon} from "./VacantIcon";
import {BedExitIcon} from './BedExitIcon';
import {AbsentIcon} from "./AbsentIcon";
import {NoMovementIcon} from "./NoMovementIcon";
import {OutOfBedIcon} from "./OutOfBedIcon";
import {RoomStatus} from "../../../pages/HomeArea/Interfaces";
import {UnknownIcon} from "./UnknownIcon";

export const getStatusIcon = (action: RoomStatus) => {
  switch (action) {
    case 'sedentary':
      return <SedentaryIcon/>;
    case 'no-movement':
      return <NoMovementIcon/>
    case 'active':
    case 'present':
      return <ActiveIcon/>;
    case 'walking':
      return <WalkingIcon/>;
    case 'out-of-room':
    case 'absent':
      return <OutOfRoomIcon/>;
    case 'in-bed':
      return <InBedIcon/>
    case 'bed-exit':
      return <BedExitIcon/>
    case 'out-of-bed':
      return <OutOfBedIcon/>
    case 'in-washroom':
      return <WashRoomIcon/>;
    case 'unknown':
      return <UnknownIcon/>;
    case 'fall':
      return <FallIcon/>;
    case 'vacant':
      return <VacantIcon/>;
  }
}
